<template>
    <div class="inventory-item card-body" v-if="p_inventory.isVisible">
        <div type="button" class="card-header">
            {{ p_inventory.inventoryName }}
        </div>
        <div class="content">
            <div class="administrator-button-bar">
                <button class="administrator-button" @click="onSave(p_inventory)" v-show="isDirty">
                    Save
                </button>
                <button class="administrator-button button-delete" @click="onDelete(p_inventory)" v-show="p_inventory.inventoryId !== -1">
                    Delete
                </button>
            </div>
            <div class="inventory-input-container">
                <span>Inventory Name:</span>
                <input @KeyDown="markDirty" class="inventory-input-item" type="text" v-model="p_inventory.inventoryName" />
            </div>
            <div class="inventory-input-container">
                <span>Inventory Type:</span>
                <select class="inventory-input-item-select" v-model="p_inventory.inventoryType" @change="typeChanged">
                    <option v-bind:value="InventoryTypes.Inventory">Sale Item</option>
                    <option v-bind:value="InventoryTypes.Hotkey">Hotkey</option>
                </select>
            </div>
            <div class="inventory-input-container">
                <span :disabled="p_inventory.inventoryType === InventoryTypes.Inventory">Hot Key:</span>
                <select class="inventory-input-item-select" v-model="p_inventory.hotkeyId" @change="hotkeyChanged" :disabled="p_inventory.inventoryType === InventoryTypes.Inventory">
                    <option v-for="hotkey in p_hotkeys" :key="hotkey.keyId" :value="hotkey.keyId">{{ hotkey.keyCaption }}</option>
                </select>
            </div>
            <div class="inventory-input-container">
                <span>MSRP:</span>
                <input @KeyDown="markDirty" @change="markDirty" class="inventory-input-item administrator-input-item-numeric" type="number" min="-999" max="99999" v-model.number="p_inventory.msrp" />
            </div>
            <div class="inventory-input-container">
                <span>Cost:</span>
                <input @KeyDown="markDirty" @change="markDirty" class="inventory-input-item administrator-input-item-numeric" type="number" min="-999" max="99999" v-model.number="p_inventory.cost" :disabled="p_inventory.inventoryType === InventoryTypes.Hotkey"/>
            </div>

            <div class="inventory-input-container">
                <span>Quantity:</span>
                <input @KeyDown="markDirty" @change="markDirty" class="inventory-input-item administrator-input-item-numeric" type="number" min="-999" max="99999" v-model.number="p_inventory.quantity" :disabled="p_inventory.inventoryType === InventoryTypes.Hotkey"/>
            </div>

            <div class="inventory-input-container">
                <span>Point Value:</span>
                <input @KeyDown="markDirty" @change="markDirty" class="inventory-input-item administrator-input-item-numeric" type="number" min="-999" max="99999" v-model.number="p_inventory.pointValue" />
            </div>

            <div class="inventory-input-container">
                <span>Point Redeem:</span>
                <input @KeyDown="markDirty" @change="markDirty" class="inventory-input-item administrator-input-item-numeric" type="number" min="-999" max="99999" v-model.number="p_inventory.pointRedeem" />
            </div>

            <div class="inventory-input-container">
                <span>UPC:</span>
                <input @KeyDown="markDirty" class="inventory-input-item" type="text" v-model="p_inventory.upc" />
            </div>

            <div class="inventory-input-container">
                <span>Menu Group:</span>
                <select class="inventory-input-item-select" v-model="p_inventory.menuGroupId" @change="markDirty">                                        
                    <option v-for="menuGroup in p_menuGroups" :key="menuGroup.menuGroupId" :value="menuGroup.menuGroupId"><span v-for="index in menuGroup.menuLevel" :key="index" >&nbsp;&nbsp;&nbsp;&nbsp;</span>{{ menuGroup.menuGroupName }}</option>
                </select>
            </div>

            <div class="inventory-input-container">
                <span>Tax Rates:</span>
                <SelectCheckbox :caption="'Select Tax Rates'" @on-change="onTaxChange" :itemKey="p_inventory.inventoryId" :items="taxItems" class="inventory-input-item-select"></SelectCheckbox>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { InventoryTypes } from "../../common/enums";
import apiInventory from "../../api/apiInventory";
import SelectCheckbox from "../../components/Shared/SelectCheckbox";
import SelectCheckboxItem from "../../models/SelectCheckboxItem";

export default {
    components: { SelectCheckbox },
    props: ["inventory", "taxes", "hotkeys", "menuGroups"],
    setup(props, { emit }) {
        const p_inventory = ref(props.inventory);
        const isDirty = ref(false);
        const taxItems = ref([]);
        const p_hotkeys = ref(props.hotkeys);
        const p_menuGroups = ref(props.menuGroups);

        props.taxes.forEach((element) => {
            var checked = p_inventory.value.taxRates.includes(element.taxId);
            taxItems.value.push(new SelectCheckboxItem(element.taxId, element.taxName, checked));
        });

        const onSave = async (item) => {
            
            emit("on-serverprocessing", true);
            try {
                await apiInventory.save(item);
                isDirty.value = false;
                emit("on-dirty", false);
            } catch (err) {
                emit("on-error", "Error saving inventory rate");
            } finally {
                emit("on-serverprocessing", false);
            }
        };

        const onDelete = (item) => {
            emit("on-delete", item);
        };

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };

        const show = ref(true);
        const showCheckboxes = () => {
            var checkboxes = document.getElementById("checkBoxes");

            if (show.value) {
                checkboxes.style.display = "block";
                show.value = false;
            } else {
                checkboxes.style.display = "none";
                show.value = true;
            }
        };

        const onTaxChange = (inventoryId, taxId, checked) => {
            if (checked && !p_inventory.value.taxRates.includes(taxId)) {
                // Add
                p_inventory.value.taxRates.push(taxId);
            }

            if (!checked) {
                // remove
                var index = p_inventory.value.taxRates.indexOf(taxId);
                if (index > -1) p_inventory.value.taxRates.splice(index, 1);
            }            
            markDirty();
        };

        const typeChanged = () => {            
            if (p_inventory.value.inventoryType === InventoryTypes.Hotkey && p_inventory.value.hotkeyId === null) {
                if (p_hotkeys.value.length > 0) {            
                    p_inventory.value.hotkeyId = p_hotkeys.value[0].keyId;
                }
            } else {
                p_inventory.value.hotkeyId = null;
            }
            hotkeyChanged();
            markDirty();
        };
        
        const hotkeyChanged = () =>
        {
            if (p_inventory.value.hotkeyId !== null) {
                var item = p_hotkeys.value.filter(x => x.keyId == p_inventory.value.hotkeyId)[0]
                p_inventory.value.msrp = item.price;
            }
            else
            {
                p_inventory.value.msrp = 0;
            }
            markDirty();
        }
        

        return {
            onSave,
            onDelete,
            p_inventory,
            isDirty,
            markDirty,
            InventoryTypes,
            showCheckboxes,
            taxItems,
            onTaxChange,
            p_hotkeys,
            typeChanged,
            hotkeyChanged,
            p_menuGroups
        };
    }
};
</script>

<style>
.inventory-item {
    width: 350px;
    min-width: 100px;
}
.inventory-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.inventory-input-item {
    width: 150px;
}

.inventory-input-item-select {
    width: 161px;
}
</style>
