Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

Date.prototype.dateEqual = function(day) {
    var thisDate = new Date(this.valueOf());    
    var value = (thisDate.getFullYear() == day.getFullYear()) &&
    (thisDate.getMonth() == day.getMonth()) &&
    (thisDate.getDate() == day.getDate())    
    return value;
}


Date.prototype.toDateOnly = function() {
    var thisDate = new Date(this.valueOf());    
    return new Date(thisDate.getFullYear(), thisDate.getMonth(), thisDate.getDate());
}