import { CustomerRelationshipTypes } from "../common/enums";

export default class CustomerModel {

    isVisible: boolean = true;

    customerNumber: number = -1;
    parentCustomerNumber: number = -1;
    hasChildren: boolean = false;

    lastname: String = "";
    firstname: String = "";
    email: String = "";
    mobile: String = "";
    balance: number = 0;
    categoryNumber: number = -1;
    categoryName: String = "";

    address: String = "";
    address2: String = "";
    city: String = "";
    state: String = "";
    zip: String = "";
    country: String = "";
    phone: String = "";    
    notes: String = "";
    hasRangeCard: boolean = false;

    getRelationship() : CustomerRelationshipTypes {
        if (this.customerNumber !== this.parentCustomerNumber)
            return CustomerRelationshipTypes.Child;

        if (this.hasChildren)
        return CustomerRelationshipTypes.Parent;

        return CustomerRelationshipTypes.Single;
    }
    
    getRelationshipText() : String{
        if (this.getRelationship() == CustomerRelationshipTypes.Child)
            return "Child";

        if (this.getRelationship() == CustomerRelationshipTypes.Parent)
            return "Parent";

        return "Single";        
    }

    static from(json: string): CustomerModel {
        return Object.assign(new CustomerModel(), json);
    }

}