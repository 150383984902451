import axios from "axios";
import Utilities from "../common/utilities"

export default class apiToken {
    static async login(username: string, password: string): Promise<string> {        

        try {
            let respone = await axios.post("api/Token/Login?username="+username+"&password="+password);
            
            return respone.data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    static async keepAlive(): Promise<string> {        

        try {            
            let response = await axios.post("api/Token/KeepAlive");            
            return response.data;
        } catch (err) {
            console.error(err);
            throw err;
                    
        }
    }
}
