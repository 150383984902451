export default class PortalLicenseModel {
    tenantId: string = "";
    tenantName: string = "";
    address: string = "";
    tenantLicense: string = "";
    isEnabled: boolean = false;
    enablePos: boolean = false;
    enableScheduler: boolean = false;
    isVisible: boolean = true; 


    static from(json: string): PortalLicenseModel {
        let model = Object.assign(new PortalLicenseModel(), json);
        return model;
    }

}