import InventoryModel from "../models/InventoryModel";
import axios from "axios";

export default class apiInventory {
    static async getAll(): Promise<Array<InventoryModel>> {
        let models = new Array<InventoryModel>();

        let respone = await axios.post("api/Inventory/getAll");
        respone.data.models.forEach((item: any) => models.push(InventoryModel.from(item)));

        return models;
    }

    static async create(): Promise<InventoryModel> {
        let respone = await axios.post("api/Inventory/create");
        let model = InventoryModel.from(respone.data.model);

        return model;
    }

    static async save(inventory: InventoryModel): Promise<void> {
        let respone = await axios.post("api/Inventory/save", {
            model: inventory
        });
    }

    static async delete(inventoryId: number): Promise<void> {
        let respone = await axios.post("api/Inventory/delete", {
            ModelId: inventoryId
        });
    }

    static async uploadFile(data: any): Promise<void> {        
        let respone = await axios.post("api/Inventory/UploadFile", data, { headers: { "Content-Type": "multipart/form-data" } });    
    }
}
