<template>
    <div class="setting-item">
        <div class="setting-header">
            <div>Card Reload</div>
            <div class="setting-header-bar"><hr /></div>
            <div style="display:flex;flex-direction:column">
                <div class="setting-input-container">
                    <span class="setting-name">Small:</span>
                    <select class="setting-input-item" v-model.number="p_settings.piReloadOptions[0].hotKeyId" @change="onSave">
                        <option v-for="hotkey in p_hotkeys" :key="hotkey.keyId" :value="hotkey.keyId">{{ hotkey.keyCaption }}</option>
                    </select>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Medium:</span>
                    <select class="setting-input-item" v-model.number="p_settings.piReloadOptions[1].hotKeyId" @change="onSave">
                        <option v-for="hotkey in p_hotkeys" :key="hotkey.keyId" :value="hotkey.keyId">{{ hotkey.keyCaption }}</option>
                    </select>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Large:</span>
                    <select class="setting-input-item" v-model.number="p_settings.piReloadOptions[2].hotKeyId" @change="onSave">
                        <option v-for="hotkey in p_hotkeys" :key="hotkey.keyId" :value="hotkey.keyId">{{ hotkey.keyCaption }}</option>
                    </select>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">King:</span>
                    <select class="setting-input-item" v-model.number="p_settings.piReloadOptions[3].hotKeyId" @change="onSave">
                        <option v-for="hotkey in p_hotkeys" :key="hotkey.keyId" :value="hotkey.keyId">{{ hotkey.keyCaption }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { HotKeyTypes } from "../../common/enums";
import apiSettings from "../../api/apiSettings";
import HotkeyModel from "../../models/HotkeyModel"

export default {
    props: ["settings", "hotkeys"],
    components: {},
    setup(props, { emit }) {
        const p_settings = ref(props.settings);
        var validHotkeys = [];

        validHotkeys
        validHotkeys.push(HotKeyTypes.RangeCard);
        validHotkeys.push(HotKeyTypes.RangeCardAuto);        
        const p_hotkeys = ref(props.hotkeys.filter((x) => validHotkeys.includes(x.hotKeyType)));

        var emptyModel = new HotkeyModel()
        emptyModel.keyId = -1
        emptyModel.keyCaption = "Empty"
        p_hotkeys.value.unshift(emptyModel)

        const onSave = async () => {            
            try {
                emit("on-serverprocessing", true);
                await apiSettings.saveCardReload(p_settings.value.piReloadOptions);
            } catch (err) {
                console.error(err);
                emit("on-error", "Error saving hot key");
            }finally {
                emit("on-serverprocessing", false);
            }
        };

        return { HotKeyTypes, p_settings, p_hotkeys, onSave };
    }
};
</script>

<style></style>
