<template>
    <div class="template-base">
        <MessageBox :caption="messageBox_caption" :message="messageBox_Message" :tag="messageBox_Tag" v-if="messageBox_show" @on-no="onMessageBoxNo" @on-yes="onMessageBoxYes_DeleteTaxRate" />
        <Header Title="Tax Rates" class="div-header" :isProcessing="fetchData || isProcessing" :errorMessage="errorMessage" :processingMessage="processingMessage"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="AddTaxRate" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" />
            </div>            
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;">
            <div class="select-button-container">
                <TaxRateItem v-for="taxrate in taxrates" :key="taxrate.taxId" :taxrate="taxrate" :categoryList="categoryList" @on-error="onError" @on-delete="onDelete" @on-dirty="onDirty" @on-serverprocessing="onServerProcessing" />
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import apiTax from "../../api/apiTax";
import Header from "../../components/Shared/Header";
import TaxRateItem from "../../components/Administrator/TaxRateItem";
import Button from "../../components/Shared/Button";
import MessageBox from "../../components/Shared/MessageBox";
import ButtonModel from "../../models/ButtonModel";
import { ref } from "vue";
import globalStatus from "../../composables/globalStatus";

export default {
    components: { Header, TaxRateItem, Button, MessageBox },
    setup() {
        const { fetchData, isProcessing, errorMessage, resetStatus, processingMessage, setMessage } = globalStatus();

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");
        const btnAdd = ButtonModel.CreateClick("New Tax Rate");

        var taxrates_base = [];
        const taxrates = ref([]);
        const searchNameText = ref("");

        const searchKeyType = ref(-1);

        const messageBox_caption = ref("");
        const messageBox_Message = ref("");
        const messageBox_Tag = ref(null);
        const messageBox_show = ref(false);

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const updateFilter = () => {
            taxrates.value.forEach((item) => {
                var isVisible = item.taxName.includes(searchNameText.value);
                if (searchKeyType.value != -1) isVisible = isVisible && item.hotKeyType == searchKeyType.value;

                item.isVisible = isVisible;
            });
        };

        const onError = (err) => {
            console.error(err);
            errorMessage.value = err;
        };

        const getData = async () => {
            try {
                resetStatus("Loading...");
                taxrates_base = await apiTax.getAll();
                taxrates.value = taxrates_base;
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data";
            }
            fetchData.value = false;
        };
        getData();

        const AddTaxRate = async () => {
            try {
                resetStatus("Creating Tax Rate...");
                var newTaxRate = await apiTax.create();
                taxrates.value.unshift(newTaxRate);
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error creating new tax rate";
            }
            fetchData.value = false;
        };

        const onDelete = async (item) => {
            messageBox_caption.value = "Delete Tax Rate";
            messageBox_Message.value = "Are you sure you want to delete tax rate " + item.taxName + "?";
            messageBox_Tag.value = item;
            messageBox_show.value = true;            
        };

        const onMessageBoxYes_DeleteTaxRate = async (item) => {
            try {
                resetStatus("Deleting Tax Rate...");
                await apiTax.delete(item.taxId);
                var index = taxrates.value.indexOf(item);
                if (index > -1) taxrates.value.splice(index, 1);
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error deleting tax rate";
            }
            fetchData.value = false;

            onMessageBoxNo();
        };

        const onMessageBoxNo = () => {
            messageBox_show.value = false;
            messageBox_caption.value = "";
            messageBox_Message.value = "";
            messageBox_Tag.value = null;
        };

        const onServerProcessing = (process) => {
            isProcessing.value = process
            if (process) setMessage("Saving...");
        };

        return {
            btnExit,
            btnAdd,
            taxrates,            
            fetchData,
            errorMessage,
            onError,
            searchNameText,
            updateFilter,
            searchKeyType,        
            AddTaxRate,
            onDelete,
            messageBox_caption,
            messageBox_Message,
            messageBox_Tag,
            messageBox_show,
            onMessageBoxNo,
            onMessageBoxYes_DeleteTaxRate,
            onDirty, processingMessage, onServerProcessing, isProcessing
        };
    }
};
</script>

<style></style>
