import Settings from "./settings";
import apiToken from "@/api/apiToken";

class Utilities {
    static toCurrency(value: number): string {
        return new Intl.NumberFormat(Settings.locale, { style: "currency", currency: Settings.currency }).format(value);
    }

    static toPercent(value: number): string {
        return new Intl.NumberFormat(Settings.locale, { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    }

    static IsEmail(email: string): boolean {
        var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    static IsPhone(phone: string): boolean {
        // This is for USA phone numbers
        var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        //var regex = /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
        //var regex = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;
        return regex.test(phone);
    }

    static parseJwt(token: string): any {
        if (token === null) return "";
        try {
            var base64Url = token.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function(c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        } catch (err) {
            return "";
        }
    }

    static _timerID: any;
    static startKeepAlive(): void {
        this._timerID = setInterval(function() {            
            apiToken.keepAlive();
        }, 120 * 1000);
    }

    static stopKeepAlive(): void {
        clearInterval(this._timerID);
    }
}
export default Utilities;
