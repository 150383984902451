import Utilities from "../common/utilities";
import { InventoryTypes } from "../common/enums";
import TaxModel from "./TaxModel"

export default class InventoryModel {
    inventoryId: string = "";
    inventoryName: string = "";
    msrp: number = 0;
    cost: number = 0;
    upc: string = "";
    isVisible: boolean = true;
    inventoryType: InventoryTypes = InventoryTypes.Inventory;
    taxRates: Array<string> = [];
    quantity: number = 0;
    pointValue: number = 0;
    pointRedeem: number = 0;
    menuGroupId: string = "";
    hotkeyId: string = "";

    static from(json: string): InventoryModel {
        return Object.assign(new InventoryModel(), json);
    }

    static FindItem(list: Array<InventoryModel>, searchText: string) {
        
        // Search by name
        var foundItems = list.filter((x) => x.inventoryName.toLowerCase() === searchText.toLowerCase());
        if (foundItems.length === 1) {
            return foundItems[0];
        }

        // Search by Barcode
        foundItems = list.filter((x) => x.upc !== null && x.upc.toLowerCase() === searchText.toLowerCase());
        if (foundItems.length === 1) {
            return foundItems[0];
        }

        return null;
    }

    GetTaxRate(taxRateList: Array<TaxModel>) : number {
        let rate = 0;

        var rates = taxRateList.filter(x=> this.taxRates.includes(x.taxId));

        rates.forEach(element =>
            {
                rate += element.rate;
            });        
        return rate;
    }
}
