<template>
    <div style="display:flex; flex-direction: column;justify-content: flex-start;">
        <EntryBar style="width:100%" @item-selected="onClicked" :inventoryList="inventoryList" />
        <div style="display:flex;flex-direction:row; margin-bottom:20px; margin-left:20px">
            <span>                
                <button class="button-link" @click="homeClick()">Home</button>
            </span>

            <span v-for="parent in parents" :key="parent.menuGroupId">
                <span v-show="parent.menuGroupId !== '00000000-0000-0000-0000-000000000001'">&nbsp;|&nbsp;</span>
                <button class="button-link" @click="parentClick(parent)">{{parent.menuGroupName}}</button>
            </span>
        </div>
        <div class="quick-keys">
            <Button class="smaller-button" v-for="hotkey in hotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" style="width:100px;font-size:10pt;font-weight:200" @on-clicked="onClicked(hotkey.tag)" />
        </div>
    </div>
</template>

<script>
import { computed, onUpdated, ref } from "vue";
import ButtonModel from "../../models/ButtonModel";
import EntryBar from "../PointOfSale/EntryBar";

import Button from "../Shared/Button";

export default {
    props: ["inventoryList", "menuGroupItems"],
    components: { Button, EntryBar },
    setup(props, { emit }) {        
        const parentId = ref("00000000-0000-0000-0000-000000000001");
        const parents = ref([]);        


        const hotkeys = computed(() => {
            var buttons = [];

            props.menuGroupItems
                .filter((x) => x.parentId == parentId.value)
                .forEach((element) => {
                    let button = ButtonModel.CreateClickColor(element.menuGroupName, "var(--menuGroup-backgroud)");
                    button.tag = element;
                    buttons.push(button);
                });

            props.inventoryList
                .filter((i) => i.menuGroupId === parentId.value)
                .forEach((element) => {
                    let button = ButtonModel.CreateClick(element.inventoryName);
                    button.tag = element;
                    buttons.push(button);
                });

            onUpdated(() =>
            {                
            })

            return buttons;
        });        

        const parentClick = (parent) => {
            var clickedIndex = parents.value.indexOf(parent)
            var length = parents.value.length-1;            
            for(var x=clickedIndex; x<length; x++){                
                parents.value.pop();
            }
            parentId.value = parent.menuGroupId;
        };

        const homeClick = () => {            
            parents.value = []            
            parentId.value = "00000000-0000-0000-0000-000000000001"
        };

        const onClicked = (inventoryItem) => {            
            // Is this a menu group button
            if ("menuGroupName" in inventoryItem) {
                parents.value.push(props.menuGroupItems.filter((x) => x.menuGroupId == inventoryItem.menuGroupId)[0]);
                parentId.value = inventoryItem.menuGroupId;
            }
            // Is this a menu group button
            else if ("inventoryName" in inventoryItem) {
                emit("item-selected", inventoryItem);
            }
        };        

        return { hotkeys, onClicked, parents, parentClick, homeClick };
    },    
};
</script>

<style>
.quick-keys {
    background-color: var(--main-background);
    align-content: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
    /* border-left-style: solid;
    border-left-width: 1px; */
}
</style>
