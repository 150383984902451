<template>
    <div class="template-base">
        <MessageBox :caption="messageBox_caption" :message="messageBox_Message" :tag="messageBox_Tag" v-if="messageBox_show" @on-no="onMessageBoxNo" @on-yes="onMessageBoxYes_Delete" />
        <Header Title="Inventory" class="div-header" :isProcessing="fetchData || isProcessing" :errorMessage="errorMessage" :processingMessage="processingMessage"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="Add" />
            <Button class="x-smaller-button" :buttonModel="btnFileUpload" @on-clicked="uploadFile" />            
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" />
            </div>
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;">
            <div class="select-button-container">
                <InventoryItem v-for="inventoryItem in inventoryItems" :key="inventoryItem.inventoryId" :inventory="inventoryItem" @on-error="onError" @on-delete="onDelete" @on-dirty="onDirty" :taxes="taxes" :hotkeys="hotkeys" :menuGroups="menuGroups" @on-serverprocessing="onServerProcessing"/>
            </div>
        </div>
        <FileUpload v-if="showFileUpload" @close="toggleFileUpload" />
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import Header from "../../components/Shared/Header";
import InventoryItem from "../../components/Administrator/InventoryItem";
import Button from "../../components/Shared/Button";
import MessageBox from "../../components/Shared/MessageBox";
import { HotKeyTypes } from "../../common/enums";
import ButtonModel from "../../models/ButtonModel";
import { ref } from "vue";
import globalStatus from "../../composables/globalStatus";
import apiInventory from "../../api/apiInventory";
import apiTax from "../../api/apiTax";
import apiHotkey from "@/api/apiHotkey";
import apiMenuGroup from "@/api/apiMenuGroup";
import FileUpload from "../../components/Shared/FileUpload";

export default {
    components: { Header, InventoryItem, Button, MessageBox, FileUpload },
    setup() {
        const { fetchData, isProcessing, errorMessage, resetStatus, processingMessage, setMessage } = globalStatus();

        const showFileUpload = ref(false);

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");
        const btnAdd = ButtonModel.CreateClick("New Inventory Item");
        const btnFileUpload = ButtonModel.CreateClick("Import Items");

        var inventoryItems_base = [];
        const inventoryItems = ref([]);
        const searchNameText = ref("");

        const searchKeyType = ref(-1);

        const messageBox_caption = ref("");
        const messageBox_Message = ref("");
        const messageBox_Tag = ref(null);
        const messageBox_show = ref(false);

        const taxes = ref([]);
        const hotkeys = ref([]);
        const menuGroups = ref([]);

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const updateFilter = () => {
            inventoryItems.value.forEach((item) => {
                var isVisible = item.inventoryName.includes(searchNameText.value);
                if (searchKeyType.value != -1) isVisible = isVisible && item.hotKeyType == searchKeyType.value;

                item.isVisible = isVisible;
            });
        };

        const onError = (err) => {
            console.error(err);
            errorMessage.value = err;
        };

        const getData = async () => {
            try {
                var hotkeyTypes = [];
                hotkeyTypes.push(HotKeyTypes.PinTicketSmall);
                hotkeyTypes.push(HotKeyTypes.PinTicketMedium);
                hotkeyTypes.push(HotKeyTypes.PinTicketKingSize);
                hotkeyTypes.push(HotKeyTypes.PinTicketCustom);
                hotkeyTypes.push(HotKeyTypes.RangeCard);

                resetStatus("Loading...");
                menuGroups.value = await apiMenuGroup.getAll();
                taxes.value = await apiTax.getAll();
                hotkeys.value = await apiHotkey.getActiveByType(hotkeyTypes);
                inventoryItems_base = await apiInventory.getAll();
                inventoryItems.value = inventoryItems_base;
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data";
            }
            fetchData.value = false;
        };
        getData();

        const Add = async () => {
            try {
                resetStatus("Creating Inventory Item...");
                var newInventory = await apiInventory.create();
                inventoryItems.value.unshift(newInventory);
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error creating new inventory item";
            }
            fetchData.value = false;
        };

        const onDelete = async (item) => {
            messageBox_caption.value = "Delete Inventory Item";
            messageBox_Message.value = "Are you sure you want to delete inventory item " + item.inventoryName + "?";
            messageBox_Tag.value = item;
            messageBox_show.value = true;
        };

        const onMessageBoxYes_Delete = async (item) => {
            try {
                resetStatus("Deleting Inventory Item...");
                await apiInventory.delete(item.inventoryId);
                var index = inventoryItems.value.indexOf(item);
                if (index > -1) inventoryItems.value.splice(index, 1);
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error deleting inventory item";
            }
            fetchData.value = false;

            onMessageBoxNo();
        };

        const onMessageBoxNo = () => {
            messageBox_show.value = false;
            messageBox_caption.value = "";
            messageBox_Message.value = "";
            messageBox_Tag.value = null;
        };

        const toggleFileUpload = () => {
            showFileUpload.value = !showFileUpload.value;
            if (!showFileUpload.value) {
                getData();
            }
        };

        const uploadFile = () => {
            toggleFileUpload()
        }

        const onServerProcessing = (process) => {
            isProcessing.value = process
            if (process) setMessage("Saving...");
        };

        return {
            btnExit,
            btnAdd,
            btnFileUpload,
            inventoryItems,
            fetchData,
            errorMessage,
            onError,
            searchNameText,
            updateFilter,
            searchKeyType,
            HotKeyTypes,
            Add,
            onDelete,
            messageBox_caption,
            messageBox_Message,
            messageBox_Tag,
            messageBox_show,
            onMessageBoxNo,
            onMessageBoxYes_Delete,
            onDirty,
            taxes,
            hotkeys,
            menuGroups,
            toggleFileUpload,
            showFileUpload,
            uploadFile, processingMessage, onServerProcessing, isProcessing
        };
    }
};
</script>

<style></style>
