<template>
    <div class="cart-item">
        <div class="cart-item-quantity cart-item-common">
            <button
                class="cart-item-button"
                @click="onDecrement()"
                :disabled="qty <= 1"
            >
                -
            </button>
            <div class="cart-item-qty">{{ qty }}</div>
            <button class="cart-item-button" @click="onIncrement()" :disabled="qty >= 200">+</button>
        </div>

        <div class="cart-item-name cart-item-common">
            {{ cartItem.inventoryItem.inventoryName }}
        </div>
        <div class="cart-item-price cart-item-common">{{ price }}</div>
        <div class="cart-item-tax cart-item-common">{{ taxRate }}</div>
        <div class="cart-item-total cart-item-common">{{ itemTotalPrice }}</div>
        <div class="cart-item-end">
            <button class="cart-item-button-delete" @click="onDelete()" >Delete</button>
        </div>
    </div>
</template>

<script>
import { ref, computed } from "vue";

import Utilities from "../../common/utilities"

export default {
    props: ["cartItem", "taxes"],

    setup(props, { emit }) {            
        const qty = computed(() => props.cartItem.quantity)

        const price = ref(Utilities.toCurrency(props.cartItem.inventoryItem.msrp))
        const taxRate = ref(Utilities.toPercent(props.cartItem.inventoryItem.GetTaxRate(props.taxes)))
        
        

        const itemTotalPrice = computed(() => {                   
            return (Utilities.toCurrency(qty.value * props.cartItem.inventoryItem.msrp * (1.0 + props.cartItem.inventoryItem.GetTaxRate(props.taxes))))
        });
                        
        const onIncrement = () => {                     
            emit ('on-increment', props.cartItem.inventoryItem.inventoryId)
        };

        const onDecrement = () => {
            emit ('on-decrement', props.cartItem.inventoryItem.inventoryId)
        };

        const onDelete = () => {
            emit ('delete-cart-item', props.cartItem.inventoryItem.inventoryId)
        };

        return { qty, price, taxRate, itemTotalPrice, onIncrement, onDecrement,onDelete };
    },
};
</script>

<style>
.cart-item {
    /* min-width: 600px; */        
    height: 50px;
    background-color: white;
    color:black;
    /* border-bottom: 1px solid black; */
    display: flex;
    flex-direction: row;
}

.cart-item-common {
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    display: flex;    
}

.cart-item-end {
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    display: flex;
}

.cart-item-quantity {
    display: flex;
    align-items: center;
    width: 7vw;
}

.cart-item-qty {
    width: 2vw;
    text-align: right;
    padding-left: 5px;
    padding-right: 5px;
}

.cart-item-button {
    height: 40px;
    width: 40px;
    background-color: cornflowerblue;
    padding: 2px;
    margin-left: 2px;
}

.cart-item-button-delete {
    height: 30px;
    width: 80px;
    background-color: red;
    padding: 2px;
    margin-left: 2px;
}

.cart-item-name {
    width: 20vw;
}

.cart-item-price {
    width: 4vw;
    text-align: right;
    justify-content: flex-end;
}

.cart-item-tax {
    width: 4vw;
    text-align: right;
    justify-content: flex-end;
}

.cart-item-total {
    width: 4vw;
    text-align: right;
    justify-content: flex-end;
}

.btn-disable {
    cursor: not-allowed;
    pointer-events: none;

    /*Button disabled - CSS color class*/
    color: #c0c0c0;
    background-color: #ffffff;
}
</style>
