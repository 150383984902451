<template>
    <div class="select-container-rows command-bar">
        <div class="select-container-rows" style="width:50%;justify-content: flex-start;">
            <Button class="smaller-button" :buttonModel="btnSales" :disable="cartItems.length > 0" />
            <Button class="smaller-button" :buttonModel="btnRecall" :disable="cartItems.length > 0" />
            <Button class="smaller-button" :buttonModel="btnTill" :disable="cartItems.length > 0" v-if="false" />
            <Button class="smaller-button" :buttonModel="btnCustomer" :disable="cartItems.length < 1" />
            <Button class="smaller-button" :buttonModel="btnExit" :disable="cartItems.length > 0" />
        </div>
        <div class="select-container-rows" style="width:50%;justify-content: flex-end;">
            <Button class="smaller-button" :buttonModel="btnAbort" :disable="cartItems.length < 1" />
            <Button class="smaller-button" :buttonModel="btnTender" :disable="cartItems.length < 1" />
        </div>
        <CustomerList v-if="showCustomerListModal" @close="toggleCustomerListModal" @on-select="onSelect" @on-view="onView" />
        <CustomerDetails v-if="showCustomerDetailModal" @close="toggleCustomerDetailModal" @on-select="onSelect" />
    </div>
</template>

<script>
import Button from "../Shared/Button";
import ButtonModel from "../../models/ButtonModel";
import CustomerList from "../Shared/CustomerList";
import CustomerDetails from "../Shared/CustomerDetails";
import { ref } from "vue";

export default {
    props: ["cartItems"],
    components: { Button, CustomerList, CustomerDetails },
    setup(props, { emit }) {
        const showCustomerListModal = ref(false);
        const showCustomerDetailModal = ref(false);

        const btnSales = ButtonModel.CreateClick("Sales");
        

        const btnTill = ButtonModel.CreateClick("Till");
        

        const btnCustomer = ButtonModel.CreateAction("Customer", () => {
            showCustomerListModal.value = !showCustomerListModal.value;
        });
        

        const btnExit = ButtonModel.CreateRoute("Log Off", "Home");

        const btnAbort = ButtonModel.CreateAction("Abort", () => {
            emit("on-abort");
        });        

        const btnRecall = ButtonModel.CreateClick("Recall");    

        const btnTender = ButtonModel.CreateClick("Tender");        

        const toggleCustomerListModal = () => {
            showCustomerListModal.value = !showCustomerListModal.value;
        };
        const toggleCustomerDetailModal = () => {
            showCustomerDetailModal.value = !showCustomerDetailModal.value;
        };

        const onView = (customer) => {
            showCustomerListModal.value = !showCustomerListModal.value;
            showCustomerDetailModal.value = true;
            console.debug(customer);
        };

        const onSelect = (customer) => {
            showCustomerListModal.value = !showCustomerListModal.value;
            //selectedCustomer.value = customer
            console.debug(customer);
        };

        return {
            btnSales,
            btnTill,
            btnExit,
            btnAbort,
            btnRecall,
            btnTender,
            btnCustomer,
            toggleCustomerListModal,
            toggleCustomerDetailModal,
            showCustomerListModal,
            showCustomerDetailModal,
            onView,
            onSelect
        };
    }
};
</script>

<style>
.command-bar {
    margin: auto;
    border-top-style: solid;
    border-top-width: 1px;
}
</style>
