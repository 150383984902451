import axios from "axios";
import NewUserModel from "../models/NewUserModel"
import PortalUserModel from "../models/PortalUserModel"

export default class apiUser {

    static async getAll(): Promise<Array<PortalUserModel>> {
        let models = new Array<PortalUserModel>();

        let respone = await axios.post("api/user/getAll");
        respone.data.models.forEach((item: any) =>
            models.push(PortalUserModel.from(item))
        );
        
        return models;
    }

    static async add(newUser: NewUserModel): Promise<PortalUserModel> {
        let respone = await axios.post("api/user/add", newUser);
        let model = PortalUserModel.from(respone.data.model)            

        return model;
    }

    static async update(user: PortalUserModel): Promise<void> {
        let respone = await axios.post("api/user/update", {
            model: user,
        });
    }

    static async updatePasskey(username: String, passkey: String): Promise<void> {
        let respone = await axios.post("api/user/updatePasskey", {
            username : username,
            passkey : passkey
        });
    }
}
