<template>
    <div class="template-base">
        <Header Title="Tenant Management" class="div-header" :isProcessing="fetchData" :errorMessage="errorMessage" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="addTenant" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" />
            </div>
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;">
            <div class="select-button-container">
                <TenantItem v-for="tenant in tenants" :key="tenant.tenantId" :tenantModel="tenant" @on-error="onError" @on-addUser="addUser" />
            </div>
        </div>
        <NewUser v-if="newUser_show" @on-cancel="toggleNewUser" @on-createUser="createUser" :tenantId="tenantModel.tenantId" :tenantName="tenantModel.tenantName" />
        <NewTenant v-if="newTenant_show" @on-cancel="toggleNewTenant"  @on-createTenant="createTenant" />
    </div>
</template>

<script>
import Header from "../components/Shared/Header";
import Button from "../components/Shared/Button";
import ButtonModel from "../models/ButtonModel";
import { ref } from "@vue/reactivity";
import globalStatus from "../composables/globalStatus";
import TenantItem from "../components/Shared/TenantItem.vue";
import apiTenant from "../api/apiTenant";
import NewUser from "../components/Shared/NewUser";
import NewTenant from "../components/Shared/NewTenant";
import apiUser from "../api/apiUser";


export default {
    components: { Header, Button, TenantItem, NewUser, NewTenant },
    setup() {
        const { fetchData, errorMessage, resetStatus } = globalStatus();

        var tenants_base = [];
        const tenants = ref([]);
        const searchNameText = ref("");
        const newUser_show = ref(false);
        const newTenant_show = ref(false);
        const tenantModel = ref();

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");
        const btnAdd = ButtonModel.CreateClick("New Site");

        const addTenant = () => {            
            toggleNewTenant()
        };

        const onError = (err) => {
            console.error(err);
            errorMessage.value = err;
        };

        const getData = async () => {
            try {
                resetStatus("Loading...");
                tenants_base = await apiTenant.getAll();
                tenants.value = tenants_base;
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data";
            }
            fetchData.value = false;
        };
        getData();

        const updateFilter = () => {
            tenants.value.forEach((item) => {
                var isVisible = item.tenantName.includes(searchNameText.value);
                item.isVisible = isVisible;
            });
        };

        const toggleNewUser = () => {
            newUser_show.value = !newUser_show.value;
        };

        const toggleNewTenant = () => {
            newTenant_show.value = !newTenant_show.value;
        };

        const addUser = (tenant) => {
            tenantModel.value = tenant;
            newUser_show.value = !newUser_show.value;
        };

        const createUser = async (newUser) => {
            try {
                resetStatus("Creating User...");
                newUser.isAdmin = true;
                await apiUser.add(newUser);
                newUser_show.value = false;
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error creating user";
            }
            fetchData.value = false;
        };
        
        const createTenant = async (tenantName, tenantAddress) => {
            try {
                resetStatus("Creating Site...");                
                var newTenant = await apiTenant.create(tenantName, tenantAddress);
                tenants.value.push(newTenant);
                newTenant_show.value = false;                
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error creating site";
            }
            fetchData.value = false;
        };

        return { fetchData, errorMessage, onError, btnExit, btnAdd, addTenant, tenants, updateFilter, searchNameText, newUser_show, newTenant_show, toggleNewTenant, toggleNewUser, addUser, tenantModel, createUser, createTenant };
    }
};
</script>

<style></style>
