import SlotModel from "../models/SlotModel";
import AvailableSlotsResponse from "../models/AvailableSlotsResponse";
import ReserveSlotResponse from "../models/ReserveSlotResponse";
import axios from "axios";

export default class apiScheduler {    
    static async getAvailableSlots(date: Date, groupNameValue: String): Promise<AvailableSlotsResponse> {
        let model = new AvailableSlotsResponse();

        let response = await axios.post("api/Scheduler/GetAvailableSlots", {
            day: date,
            groupName : groupNameValue
        });
                
        model = AvailableSlotsResponse.from(response.data)        

        return model;
    }    

    static async reserveSlot(slot: SlotModel): Promise<ReserveSlotResponse> {
        let response = await axios.post("api/Scheduler/ReserveSlot", {
            slot: slot            
        });

        return ReserveSlotResponse.from(response.data);
    }

    static async getReservation(reservationId: String): Promise<SlotModel> {
        let response = await axios.post("api/Scheduler/GetReservation", {
            reservationId: reservationId            
        });

        return SlotModel.from(response.data.reservation);
    }

    static async cancelReservation(reservationId: String): Promise<void> {
        let response = await axios.post("api/Scheduler/CancelReservation", {
            reservationId: reservationId            
        });        
    }
}
