<template>
    <div class="template-base">
        <MessageBox :caption="messageBox_caption" :message="messageBox_Message" :tag="messageBox_Tag" v-if="messageBox_show" @on-no="onMessageBoxNo" @on-yes="onMessageBoxYes_DeleteHotKey" />
        <Header Title="Hot Keys" class="div-header" :isProcessing="fetchData" :errorMessage="errorMessage" :processingMessage="processingMessage"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="AddHotKey" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" />
            </div>
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Key Type:</div>
                <select class="hotkey-input-item" v-model="searchKeyType" :onchange="updateFilter">
                    <option value="-1">All</option>
                    <option v-bind:value="HotKeyTypes.PinTicketSmall">Pin Ticket Small</option>
                    <option v-bind:value="HotKeyTypes.PinTicketMedium">Pin Ticket Medium</option>
                    <option v-bind:value="HotKeyTypes.PinTicketLarge">Pin Ticket Large</option>
                    <option v-bind:value="HotKeyTypes.PinTicketKingSize">Pin Ticket King</option>
                    <option v-bind:value="HotKeyTypes.RangeCard">Range Card</option>
                    <option v-bind:value="HotKeyTypes.RangeCardAuto">Range Card Auto</option>
                    <option v-bind:value="HotKeyTypes.SaleItem">Sale Item</option>
                    <option v-bind:value="HotKeyTypes.PinTicketCustom">Pin Ticket Custom</option>
                    <option v-bind:value="HotKeyTypes.PinTicketCategory">Pin Ticket Category</option>
                    <option v-bind:value="HotKeyTypes.TurnstileEntry">Turnstile Entry</option>
                </select>
            </div>
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;">
            <div class="select-button-container">
                <HotkeyItem v-for="hotkey in hotkeys" :key="hotkey.keyId" :hotkey="hotkey" :categoryList="categoryList" @on-error="onError" @on-delete="onDelete" @on-dirty="onDirty" />
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import apiHotkey from "../../api/apiHotkey";
import apiCategory from "../../api/apiCategory";
import Header from "../../components/Shared/Header";
import HotkeyItem from "../../components/Administrator/HotkeyItem";
import Button from "../../components/Shared/Button";
import MessageBox from "../../components/Shared/MessageBox";
import { HotKeyTypes } from "../../common/enums";
import ButtonModel from "../../models/ButtonModel";
import { ref } from "vue";
import globalStatus from "../../composables/globalStatus";

export default {
    components: { Header, HotkeyItem, Button, MessageBox },
    setup() {
        const { fetchData, errorMessage, resetStatus, processingMessage } = globalStatus();

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");
        const btnAdd = ButtonModel.CreateClick("New Hot Key");

        var hotkeys_base = [];
        const hotkeys = ref([]);
        const categoryList = ref([]);
        const searchNameText = ref("");

        const searchKeyType = ref(-1);

        const messageBox_caption = ref("");
        const messageBox_Message = ref("");
        const messageBox_Tag = ref(null);
        const messageBox_show = ref(false);        

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const updateFilter = () => {
            hotkeys.value.forEach((item) => {
                var isVisible = item.keyCaption.includes(searchNameText.value);
                if (searchKeyType.value != -1) isVisible = isVisible && item.hotKeyType == searchKeyType.value;

                item.isVisible = isVisible;
            });
        };

        const onError = (err) => {
            console.error(err);
            errorMessage.value = err;
        };

        const getData = async () => {
            try {
                resetStatus("Loading...");
                hotkeys_base = await apiHotkey.getAll();
                hotkeys.value = hotkeys_base;
                categoryList.value = await apiCategory.getList();
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data";
            }
            fetchData.value = false;
        };
        getData();

        const AddHotKey = async () => {
            try {
                resetStatus("Creating Hotkey...");
                var newHotkey = await apiHotkey.create();
                hotkeys.value.unshift(newHotkey);
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error creating new hotkey";
            }
            fetchData.value = false;
        };

        const onDelete = async (item) => {
            messageBox_caption.value = "Delete Hot Key";
            messageBox_Message.value = "Are you sure you want to delete hot key " + item.keyCaption + "?";
            messageBox_Tag.value = item;
            messageBox_show.value = true;            
        };

        const onMessageBoxYes_DeleteHotKey = async (item) => {
            try {
                resetStatus("Deleting Hotkey...");
                await apiHotkey.delete(item.keyId);
                var index = hotkeys.value.indexOf(item);
                if (index > -1) hotkeys.value.splice(index, 1);
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error deleting hotkey";
            }
            fetchData.value = false;

            onMessageBoxNo();
        };

        const onMessageBoxNo = () => {
            messageBox_show.value = false;
            messageBox_caption.value = "";
            messageBox_Message.value = "";
            messageBox_Tag.value = null;
        };

        return {
            btnExit,
            btnAdd,
            hotkeys,
            categoryList,            
            fetchData,            
            errorMessage,
            onError,
            searchNameText,
            updateFilter,
            searchKeyType,
            HotKeyTypes,
            AddHotKey,
            onDelete,
            messageBox_caption,
            messageBox_Message,
            messageBox_Tag,
            messageBox_show,
            onMessageBoxNo,
            onMessageBoxYes_DeleteHotKey,
            onDirty,
            processingMessage
        };
    }
};
</script>

<style></style>
