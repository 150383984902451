<template>
    <div class="template-base">
        <Header Title="Select Point of Sale" class="div-header" :isProcessing="fetchData" :errorMessage="errorMessage" :processingMessage="processingMessage" />
        <div class="select-container div-body">
            <div class="select-container-columns" style="height:100%">
                <EntryBar v-if="false" style="height:5%;width:100%" @item-selected="itemSelected" :inventoryList="inventoryItems" />
                <div class="select-container-rows" style="height:80%;width:100%">
                    <Cart style="height:100%;width: 55%;" :cartItems="cartItems" :taxes="taxes" @delete-cart-item="removeCartItem" @on-increment="onIncrement" @on-decrement="onDecrement" />
                    <QuickKeys style="height:100%;width: 44%;" :menuGroupItems="menuGroupItems" :inventoryList="inventoryItems" @item-selected="itemSelected" />
                </div>
                <CommandBar style=";width:100%;height:20%" v-if="true" @on-abort="onAbort" :cartItems="cartItems" />
            </div>
        </div>
    </div>
</template>

<script>
import CartItemModel from "../models/CartItemModel";
import Header from "../components/Shared/Header";
import EntryBar from "../components/PointOfSale/EntryBar";
import Cart from "../components/PointOfSale/Cart";
import QuickKeys from "../components/PointOfSale/QuickKeys";
import CommandBar from "../components/PointOfSale/CommandBar";
import { ref } from "vue";
import globalStatus from "../composables/globalStatus";
import apiInventory from "../api/apiInventory";
import apiTax from "../api/apiTax";
import apiMenuGroup from "../api/apiMenuGroup";

export default {
    components: { Header, EntryBar, Cart, QuickKeys, CommandBar },
    setup() {
        const { fetchData, errorMessage, resetStatus, processingMessage } = globalStatus();
        const taxes = ref([]);
        const inventoryItems = ref([]);
        const menuGroupItems = ref([]);

        const getData = async () => {
            try {
                resetStatus("Loading...");
                menuGroupItems.value = await apiMenuGroup.getAll()
                taxes.value = await apiTax.getAll()
                inventoryItems.value = await apiInventory.getAll()                
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data";
            }
            fetchData.value = false;
        };
        getData();        

        const cartItems = ref([]);

        const removeCartItem = (id) => {
            cartItems.value.splice(
                cartItems.value.findIndex((item) => item.Id === id),
                1
            );
        };

        const itemSelected = (item) => {            
            // Check if item is already in cart
            var index = cartItems.value.findIndex((x) => x.inventoryItem.inventoryId == item.inventoryId);
            if (index >= 0) {
                cartItems.value[index].quantity++;
            } else {
                cartItems.value.push(new CartItemModel(item));
            }
        };

        const onIncrement = (id) => {            
            var index = cartItems.value.findIndex((x) => x.inventoryItem.inventoryId == id);
            if (index >= 0) {
                if (cartItems.value[index].quantity < 200) cartItems.value[index].quantity++;
            }
        };

        const onDecrement = (id) => {
            var index = cartItems.value.findIndex((x) => x.inventoryItem.inventoryId == id);
            if (index >= 0) {
                if (cartItems.value[index].quantity > 1) cartItems.value[index].quantity--;
            }
        };

        const onAbort = () => {
            cartItems.value = [];
        };

        return {
            itemSelected,
            cartItems,
            removeCartItem,
            onIncrement,
            onDecrement,            
            inventoryItems,
            onAbort,
            fetchData,
            errorMessage,
            taxes,
            menuGroupItems,
            processingMessage
        };
    }
};
</script>

<style></style>
