import { ref } from "vue";

const globalStatus = () => {
    const fetchData = ref(false);
    const isProcessing = ref(false);
    const errorMessage = ref("");
    const processingMessage = ref("");

    const resetStatus = (message: any) => {
        errorMessage.value = "";        
        processingMessage.value = message;        
        fetchData.value = true;        
    };

    const setMessage = (message: any) => {
        console.log("setmessage")
        errorMessage.value = "";        
        processingMessage.value = message;        
    };

    const apiExecute = async (apiMethod : any, objectValue: any) =>  {        
        try {
            resetStatus(null);
            objectValue =  await apiMethod();
        } catch (err) {
            errorMessage.value = "Error fetching data";
        } finally {
            fetchData.value = false;            
        }        
    }
    
    return { fetchData, isProcessing, errorMessage, resetStatus, processingMessage, setMessage }
};

export default globalStatus;
