<template>
    <div class="template-base">
        <Header Title="Customer Settings" class="div-header" :isProcessing="fetchData || isProcessing" :errorMessage="errorMessage" :processingMessage="processingMessage"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
        </div>
        <div class="select-button-container-preferences div-body" v-if="!fetchData">            
            <RequiredFields :settings="settings" @on-save-preferences="onSavePreferences"/>            
            <CategorySettings :settings="settings" :categoryList="categoryList" @on-save-preferences="onSavePreferences" />            
        </div>
    </div>
</template>

<script>
import Header from "../../components/Shared/Header";
import RequiredFields from "../../components/Administrator/RequiredFields";
import CategorySettings from "../../components/Administrator/CategorySettings";


import Button from "../../components/Shared/Button";
import ButtonModel from "../../models/ButtonModel";
import apiSettings from "../../api/apiSettings";
import { ref } from "vue";
import globalStatus from "../../composables/globalStatus";
import apiCategory from "../../api/apiCategory";


export default {
    components: {
        Header,
        RequiredFields,
        CategorySettings,
        Button
    },
    setup(props, { emit }) {
        const { fetchData, isProcessing, errorMessage, resetStatus, processingMessage, setMessage } = globalStatus();

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");

        const settings = ref(null)
        const hotkeys = ref(null)
        const categoryList = ref([]);

        const getData = async () => {
            try {
                resetStatus("Loading...");                
                categoryList.value = await apiCategory.getList();
                settings.value = await apiSettings.getAll()                
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data"
            }
            fetchData.value = false;
        };
        getData();

        const onSavePreferences = async () => {            
            try {                
                setMessage("Saving...");                
                isProcessing.value = true;
                await apiSettings.savePreference(settings.value);
            } catch (err) {
                console.error(err);
                emit("on-error", "Error saving settings");
            } finally {
                isProcessing.value = false;
            }
        };        

        const onServerProcessing = (process) => {
            isProcessing.value = process
            if (process) setMessage("Saving...");
        };

        return { btnExit, fetchData, isProcessing, errorMessage, processingMessage, resetStatus, settings, hotkeys, categoryList, onSavePreferences, onServerProcessing};
    }
};
</script>

<style>

</style>
