<template>
    <div class="setting-item">
        <div class="setting-header">
            <div>Bucket Size</div>
            <div class="setting-header-bar"><hr /></div>
            <div style="display:flex;flex-direction:column">
                <div class="setting-input-container">
                    <span class="setting-name">Site:</span>
                    <select class="setting-input-item">
                        <option id="0">Main</option>
                        <option id="1">Alt 2</option>
                        <option id="2">Alt 3</option>
                        <option id="3">Alt 4</option>
                        <option id="3">Alt 5</option>
                        <option id="3">Alt 6</option>
                        <option id="3">Alt 7</option>
                        <option id="3">Alt 8</option>
                        <option id="3">Alt 9</option>
                        <option id="3">Alt 10</option>
                    </select>
                </div>
                <div style="margin-top:20px" />
                <div class="setting-input-container">
                    <span class="setting-name"></span>
                    <span style="margin-right:40px" class="setting-input">Balls</span>
                    <span class="setting-input-item">Hot Key</span>                    
                </div>
                <div class="setting-input-container">
                    <span class="setting-name">Small:</span>
                    <input @change="onSave" style="margin-right:10px" class="setting-input administrator-input-item-numeric" type="number" min="0" max="1000" step="1" v-model.number="p_settings.piBuckets[0].balls" />
                    <select class="setting-input-item" v-model.number="p_settings.piBuckets[0].hotKeyId" @change="onSave">
                        <option v-for="hotkey in p_hotkeys.filter((x) => x.hotKeyType === HotKeyTypes.PinTicketSmall || x.hotKeyType === HotKeyTypes.Other)" :key="hotkey.keyId" :value="hotkey.keyId">{{ hotkey.keyCaption }}</option>
                    </select>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Medium:</span>
                    <input @change="onSave" style="margin-right:10px" class="setting-input administrator-input-item-numeric" type="number" min="0" max="1000" step="1" v-model.number="p_settings.piBuckets[1].balls" />
                    <select class="setting-input-item" v-model.number="p_settings.piBuckets[1].hotKeyId" @change="onSave">
                        <option v-for="hotkey in p_hotkeys.filter((x) => x.hotKeyType === HotKeyTypes.PinTicketMedium || x.hotKeyType === HotKeyTypes.Other)" :key="hotkey.keyId" :value="hotkey.keyId">{{ hotkey.keyCaption }}</option>
                    </select>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Large:</span>
                    <input @change="onSave" style="margin-right:10px" class="setting-input administrator-input-item-numeric" type="number" min="0" max="1000" step="1" v-model.number="p_settings.piBuckets[2].balls" />
                    <select class="setting-input-item" v-model.number="p_settings.piBuckets[2].hotKeyId" @change="onSave">
                        <option v-for="hotkey in p_hotkeys.filter((x) => x.hotKeyType === HotKeyTypes.PinTicketLarge || x.hotKeyType === HotKeyTypes.Other)" :key="hotkey.keyId" :value="hotkey.keyId">{{ hotkey.keyCaption }}</option>
                    </select>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">King:</span>
                    <input @change="onSave" style="margin-right:10px" class="setting-input administrator-input-item-numeric" type="number" min="0" max="1000" step="1" v-model.number="p_settings.piBuckets[3].balls" />
                    <select class="setting-input-item" v-model.number="p_settings.piBuckets[3].hotKeyId" @change="onSave">
                        <option v-for="hotkey in p_hotkeys.filter((x) => x.hotKeyType === HotKeyTypes.PinTicketKingSize || x.hotKeyType === HotKeyTypes.Other)" :key="hotkey.keyId" :value="hotkey.keyId">{{ hotkey.keyCaption }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import { HotKeyTypes } from "../../common/enums";
import apiSettings from "../../api/apiSettings";
import HotkeyModel from "../../models/HotkeyModel"

export default {
    props: ["settings", "hotkeys"],
    components: {},
    setup(props, { emit }) {
        const p_settings = ref(props.settings);
        var validHotkeys = [];
        validHotkeys.push(HotKeyTypes.PinTicketSmall);
        validHotkeys.push(HotKeyTypes.PinTicketMedium);
        validHotkeys.push(HotKeyTypes.PinTicketLarge);
        validHotkeys.push(HotKeyTypes.PinTicketKingSize);
        const p_hotkeys = ref(props.hotkeys.filter((x) => validHotkeys.includes(x.hotKeyType)));

        var emptyModel = new HotkeyModel()
        emptyModel.keyId = -1
        emptyModel.keyCaption = "Empty"
        p_hotkeys.value.unshift(emptyModel)


        const onSave = async () => {            
            try {
                emit("on-serverprocessing", true);
                await apiSettings.saveKioskBucketSize(p_settings.value.piBuckets);
            } catch (err) {
                console.error(err);
                emit("on-error", "Error saving hot key");
            } finally {
                emit("on-serverprocessing", false);
            }
        };

        return { HotKeyTypes, p_settings, p_hotkeys, onSave };
    }
};
</script>

<style></style>
