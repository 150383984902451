import CustomerModel from "../models/CustomerModel";
import axios from "axios";

export default class apiCustomer {

    static async getPages(filters: any): Promise<number> {
        let respone = await axios.post("api/Customer/getPages", {
            filters: filters,
        });

        return respone.data.pages;
    }

    static async getAll(page: number): Promise<Array<CustomerModel>> {
        let models = new Array<CustomerModel>();


        await new Promise(resolve => {
            setTimeout(resolve, 500)
        })

        let model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 1;
        model.parentCustomerNumber = 1;
        model.lastname = "Woods";
        model.firstname = "Tiger";
        model.email="tiger@golf.com";
        model.categoryNumber = 2;
        model.balance= 354.54;
        model.hasChildren=false;
        model.hasRangeCard=true;
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 2;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Arnold";
        model.email="palmer@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.address = "123 Main Street"
        model.address2 = "suite 2"
        models.push(model);

        model = new CustomerModel();
        model.customerNumber = 3;
        model.parentCustomerNumber = 2;
        model.lastname = "Palmer";
        model.firstname = "Debbie";
        model.email="debbie@golf.com";
        model.categoryNumber = 1;
        model.balance= 34.23;
        model.hasChildren=true;
        model.mobile = "123453423"
        models.push(model);

        // let respone = await axios.post("api/Customer/getAll");
        // respone.data.models.forEach((item: any) =>
        //     models.push(CustomerModel.from(item))
        // );

        return models;
    }

    static async add(customer: CustomerModel): Promise<CustomerModel> {
        let respone = await axios.post("api/Customer/add");
        let model = CustomerModel.from(respone.data.model)            
        return model;
    }

    static async save(customer: CustomerModel): Promise<void> {
        let respone = await axios.post("api/Customer/save", {
            model: customer,
        });
    }

    static async delete(customerNumber: string): Promise<void> {
        let respone = await axios.post("api/Customer/delete", {
            ModelId: customerNumber,
        });
    }
}
