<template>
    <div class="setting-item">
        <div class="setting-header">
            <div>Batch Pin Codes</div>
            <div class="setting-header-bar"><hr /></div>
            <div style="display:flex;flex-direction:column">
                <div class="setting-input-container">
                <span class="setting-name">Title:</span>
                <textarea
                    class="setting-input-item"
                    name="message"
                    rows="10"
                    cols="30"
                >
Batch Pin Codes</textarea
                >
            </div>


            <div class="setting-input-container">
                <span class="setting-name">Number of Balls:</span>
                <input class="setting-input-item" type="text" value="1" />
            </div>

            <div class="setting-input-container">
                <span class="setting-name">Number of Pin Codes:</span>
                <input class="setting-input-item" type="text" value="1" />
            </div>

            <div class="setting-input-container">
                <span class="setting-name">Pin Expiration Days:</span>
                <input class="setting-input-item" type="text" value="1" />
            </div>
            <div class="setting-input-container">
                <span class="setting-name">
                    <input type="checkbox" id="chkEndOfDay" />
                    <label for="chkEndOfDay">End Of Day</label>
                </span>
            </div>
            </div>
        </div>

       
    </div>
</template>

<script>
import { ref } from "vue";
import {HotKeyTypes} from "../../common/enums";

export default {
    props: [],
    components: {},
    setup() {
        const collapsed = ref(false);

        const toggleExpand = () => {
            collapsed.value = !collapsed.value;
        };

        return { collapsed, toggleExpand, HotKeyTypes };
    },
};
</script>

<style></style>
