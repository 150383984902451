import Utilities from "../common/utilities";
//import jwt from "jsonwebtoken";

export default class PortalUserModel {

    isVisible: boolean = true;

    username: string = "";
    isAdmin: boolean= false;
    isSuperUser: boolean= false;
    displayName: string = "";
    tenant: string = "";
    enablePos: boolean = false;
    enableScheduler: boolean = false;
    private exp: number = 0;

    disableAddCredit: boolean = false;
    disableRangeCardManagement: boolean = false;
    disableCustomerManagement: boolean = false;
    disableCustomerCategory: boolean = false;
    disableHotKeys: boolean = false;
    disableKiosk: boolean = false;
    disablePinTicket: boolean = false;
    disablePreferences: boolean = false;
    disableUserRights: boolean = false;

    public constructor() {
        
      }

      public IsValid() : boolean {
        if (Date.now() < this.exp * 1000) {
            return true;
          }

        return false;
      }

      static fromToken() {
        var token = localStorage.getItem("token");
        let payload = Utilities.parseJwt(token || '{}');
        
        let user = new PortalUserModel()
        user.username = payload.username;
        user.isAdmin = payload.isAdmin === 'true';
        user.displayName = payload.displayName;
        user.tenant = payload.tenant;
        user.enablePos = payload.enablePos  === 'true';
        user.enableScheduler = payload.enableScheduler  === 'true';
        user.isSuperUser = payload.isSuperUser === 'true';

        user.exp = payload.exp;

        return user;
      } 

      static from(json: string): PortalUserModel {
        let model = Object.assign(new PortalUserModel(), json);
        return model;
    }

}