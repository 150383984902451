<template>
    <div class="header-bar">
        <div style="margin:5px"><img @click="goHome()" src="../../assets/logoMedium.png" style="height:90%;padding:0px"/></div>

        <div v-if="false" style="margin:20px;font-size:14pt">{{ Title }}</div>
        <div v-if="!noSignOut" style="padding-right:10px">
            <div style="display:flex; flex-direction:column;justify-content:flex-end">
                <div style="display:flex; justify-content:flex-end">
                    <img src="../../assets/profile.png" style="width:48px;margin:3px;cursor: pointer" @click="toggleProfile()"/>
                </div>                
            </div>
        </div>
        <!-- <div v-if="isProcessing" class="spin"></div> -->
    </div>
    <div v-if="errorMessage !== undefined && errorMessage.length" class="error-alert">
        <div class="error-alert-inner">
            {{ errorMessage }}
        </div>
    </div>
    <div v-if="isProcessing" class="header-loading">
        <div class="header-loading-inner">
            {{processingMessage}}
        </div>
    </div>
    <Profile v-if="showProfile" @close="toggleProfile"/>
</template>

<script>
import { useRouter } from "vue-router";
import { ref } from "@vue/reactivity";
import Profile from "../../components/Shared/Profile";

export default {
    components: { Profile },
    props: ["Title", "isProcessing", "errorMessage", "noSignOut", "processingMessage"],
    setup() {
        var token = localStorage.getItem("token");        

        const showProfile = ref(false);
                    

        const router = useRouter();
        const goHome = () => {                        
            if (token !== null) router.push({ name: "Home" });
        };

        const toggleProfile = () => {            
            showProfile.value = !showProfile.value;         
        };        

        return { goHome, toggleProfile, showProfile };
    }
};
</script>

<style scoped>
.header-bar {
    display: flex;
    background-color: #38b449;
    color: black;
    padding: 0px;
    min-width: 1000px;
    width: 100%;
    height: 50px;
    justify-content: space-between;
}

.error-alert {
    position: absolute;
    top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
}

.error-alert-inner {
    background: crimson;
    color: white;
    text-align: center;
    width: 20%;
    border: 1px solid black;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
}

.spin {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border: 1px solid transparent;
    border-radius: 50%;
    border-top-color: white;
    animation: spin 1s linear infinite;
    align-self: center;
}

.header-loading {
    position: absolute;
    top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 999;
}

.header-loading-inner {
    color: white;
    background: #056608;
    text-align: center;
    width: 20%;
    border: 1px solid black;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
}

.user-info {
    font-size: 8pt;
    color: white;
}

@keyframes spin {
    to {
        transform: rotateZ(360deg);
    }
}
</style>
