import axios from 'axios'
 import Utilities from "./common/utilities"

axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(function(request) {
    // Do something before request is sent    

    var token = localStorage.getItem('token')
    if (token !== null) {
        request.headers['Authorization'] = 'Bearer ' + token;        
    }
    else {
        console.debug("TOKEN NOT USED")
    }

    if (request.data === undefined)
        request.data = "{}";    
    return request;
}, function(error) {
    // Do something with request error
    return Promise.reject(error);
});


// Add a response interceptor
axios.interceptors.response.use(function(response) {
    //console.debug("in response interceptors")
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error    
    
    if ((error.response.status === 401) && (!error.request.responseURL.includes('Token/Login'))) {
        localStorage.clear()            
        Utilities.stopKeepAlive()
        window.location.href = "/Login"
    }
    return Promise.reject(error);
});

