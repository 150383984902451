<template>
    <div class="cart-item-grand-total">
        <CustomerList v-if="showCustomerListModal" @close="toggleCustomerListModal" @on-select="onSelect" @on-view="onView"/>
        <CustomerDetails v-if="showCustomerDetailModal" @close="toggleCustomerDetailModal" @on-select="onSelect"/>
        


        <div class="cart-grand-total"><div>Sales Total:</div> {{ totalPrice }}</div>
        <div class="cart-grand-total"><div>Total Taxes:</div> {{ totalTax }}</div>
        <div class="cart-grand-total" style="font-weight: bold"><div>Grand Total:</div> {{ totalGrand }}</div>
    </div>    
</template>

<script>
import { computed, ref } from "vue";

import Utilities from "../../common/utilities";
import CustomerList from "../Shared/CustomerList"
import CustomerDetails from "../Shared/CustomerDetails"

export default {
    props: ["cartItems", "taxes"],
    components: { CustomerList, CustomerDetails },
    setup(props) {        

        const showCustomerListModal = ref(false)
        const showCustomerDetailModal = ref(false)
        const selectedCustomer = ref(null)
        

        const toggleCustomerListModal = () => {showCustomerListModal.value = !showCustomerListModal.value }
        const toggleCustomerDetailModal = () => {showCustomerDetailModal.value = !showCustomerDetailModal.value }

        const totalPrice = computed(() => {
            var total = 0;
            for (var i = 0; i < props.cartItems.length; i++) {
                total =
                    total + props.cartItems[i].quantity * props.cartItems[i].inventoryItem.msrp;
            }
            return Utilities.toCurrency(total);
        });

        const totalTax = computed(() => {
            var total = 0;
            for (var i = 0; i < props.cartItems.length; i++) {
                total =
                    total +
                    props.cartItems[i].quantity *
                        props.cartItems[i].inventoryItem.msrp *
                        props.cartItems[i].inventoryItem.GetTaxRate(props.taxes);
            }
            return Utilities.toCurrency(total);
        });

        const totalGrand = computed(() => {
            var total = 0;
            for (var i = 0; i < props.cartItems.length; i++) {
                total =
                    total +
                    props.cartItems[i].quantity *
                        props.cartItems[i].inventoryItem.msrp *
                        ( 1.0 + props.cartItems[i].inventoryItem.GetTaxRate(props.taxes));
            }
            return Utilities.toCurrency(total);
        });

        const onView = (customer) => {
            showCustomerListModal.value = !showCustomerListModal.value
            showCustomerDetailModal.value = true
            console.debug(customer)
        }

        const onSelect = (customer) => {
            showCustomerListModal.value = !showCustomerListModal.value
            selectedCustomer.value = customer
            console.debug(customer)
        }

        const displayCustomer = computed(() => selectedCustomer.value != null ? selectedCustomer.value.CustomerNumber + ' ' + selectedCustomer.value.FirstName + ' ' + selectedCustomer.value.LastName : "" )        

        return { totalPrice, totalTax, totalGrand, showCustomerListModal, showCustomerDetailModal, toggleCustomerListModal, toggleCustomerDetailModal, onSelect, onView, displayCustomer};
    },
};
</script>

<style>
.cart-item-grand-total {
    background-color: var(--menu-bar-backgroud);
    color: var(--menu-bar-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.cart-grand-total {
    display: flex;
    justify-content: space-between;
    font-size: 16pt;
    padding: 5px;
}
</style>
