<template>
    <div class="template-base-not-100">
        <Header Title="Player Schedule" class="div-header" :isProcessing="fetchData" :errorMessage="errorMessage" :noSignOut="true" />
        <!-- <a @click="goScheduler()">Scheduler</a> -->
        <div class="login-container div-body">
            <div div class="login-input-container">Sign In</div>
            <form @submit.prevent="Login" autocomplete="off">
                <div class="login-input-container"><input @focus="clearError" type="text" placeholder="Email" v-model="email" /></div>
                <div class="login-input-container"><input @focus="clearError" type="password" placeholder="Password" v-model="password" /></div>
                <div class="login-input-container" style="display:flex;flex-direction: row;justify-content:space-between">
                    <div>
                        <input class="button-link" type="button" value="Clear" @click="clear" />
                    </div>
                    <div>
                        <input class="button-link" type="submit" @click="login" value="Login" />
                        
                    </div>
                </div>
                <div class="login-input-container"></div>
            </form>                        
        </div>
        <div class="login-error">{{loginErrorMessage}}</div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Header from "../components/Shared/Header";
import router from "@/router";
import apiToken from "../api/apiToken";
import Utilities from "../common/utilities"

export default {
    components: { Header },
    setup() {
        const email = ref("");
        const password = ref("");
        const loginErrorMessage = ref("");

        Utilities.stopKeepAlive();

        const login = async () => {
            loginErrorMessage.value = ""
            try {
                var token = await apiToken.login(email.value, password.value);
                if (token !== null) {
                    localStorage.setItem("token", token);
                    Utilities.startKeepAlive();
                    router.replace("/Home");
                }
            } catch (err) {
                console.error(err);                
                loginErrorMessage.value = "Invalid Email and/or Password"
            }
        };

        const clear = () => {
            email.value = "";
            password.value = "";
            loginErrorMessage.value = ""
        };

        const clearError = () => {        
            loginErrorMessage.value = ""
        };
        

        const goScheduler = () => {
            router.push({ name: "PlayerScheduler" });
        };

        return { email, password, login, goScheduler, clear, loginErrorMessage, clearError };
    }
};
</script>

<style>
.login-container {
    display: flex;
    align-content: center;
    background-color: var(--main-background);
    flex-direction: column;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
}

.login-input-container {
    margin: 10px;
}

.login-error {
    color: red;
    text-align: center;
    padding: 20px;
    font-size: 11pt;
}
</style>
