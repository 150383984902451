<template>
<div class="taxrate-item card-body" v-if="p_model.isVisible">
        <div type="button" class="card-header">
            {{ p_model.displayName }}
        </div>
        <div class="content">
            <div class="administrator-button-bar">
                <button class="administrator-button" @click="onSave(p_model)" v-show="isDirty">
                    Save
                </button>
                <!-- <button class="administrator-button button-delete"  @click="onDelete(p_model)">
                    Delete
                </button> -->
            </div>
            

            <div class="taxrate-input-container">
                <span>Display Name:</span>
                <input @KeyDown="markDirty" class="taxrate-input-item" type="text" v-model="p_model.displayName" />
            </div>                            

            <div class="taxrate-input-container">
                <span>Username:</span>
                <input @KeyDown="markDirty" class="taxrate-input-item" type="text" v-model="p_model.username" readonly/>
            </div>                            

            <div class="taxrate-input-container" style="justify-content: flex-start; border-bottom:solid 1px black; padding-bottom:10px">
                <input @change="markDirty" type="checkbox" :id="'isDisabledx' + p_model.username" v-model="p_model.isDisabled"  />
                <label :for="'isDisabledx' + p_model.username">Disable Account</label>                
            </div>
        
            <div class="taxrate-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'isAdmin' + p_model.username" v-model="p_model.isAdmin"  />
                <label :for="'isAdmin' + p_model.username">Administrator</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'disableAddCredit' + p_model.username" v-model="p_model.disableAddCredit"  />
                <label :for="'disableAddCredit' + p_model.username">Disable Add Credit</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'disableRangeCardManagement' + p_model.username" v-model="p_model.disableRangeCardManagement"  />
                <label :for="'disableRangeCardManagement' + p_model.username">Disable Range Card Management</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'disableCustomerManagement' + p_model.username" v-model="p_model.disableCustomerManagement"  />
                <label :for="'disableCustomerManagement' + p_model.username">Disable Customer Management</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'disableCustomerCategory' + p_model.username" v-model="p_model.disableCustomerCategory"  />
                <label :for="'disableCustomerCategory' + p_model.username">Disable Customer Category</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'disableHotKeys' + p_model.username" v-model="p_model.disableHotKeys"  />
                <label :for="'disableHotKeys' + p_model.username">Disable Hot Keys</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'disableKiosk' + p_model.username" v-model="p_model.disableKiosk"  />
                <label :for="'disableKiosk' + p_model.username">Disable Kiosk</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'disablePinTicket' + p_model.username" v-model="p_model.disablePinTicket"  />
                <label :for="'disablePinTicket' + p_model.username">Disable Pin Ticket</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'disablePreferences' + p_model.username" v-model="p_model.disablePreferences"  />
                <label :for="'disablePreferences' + p_model.username">Disable Preferences</label>
            </div>

            <div class="taxrate-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'disableUserRights' + p_model.username" v-model="p_model.disableUserRights"  />
                <label :for="'disableUserRights' + p_model.username">Disable User Rights</label>
            </div>
            
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
import apiUser from "../../api/apiUser";

export default {
    props: ["itemmodel"],
    setup(props, { emit }) {
        
        const p_model = ref(props.itemmodel);
        const isDirty = ref(false)

        const onSave = async (item) => {
            console.debug(item)
            isDirty.value = false;
            emit("on-dirty", false)            
            try {                
                await apiUser.update(p_model.value);                
            } catch (err) {                
                console.error(err);
                emit("on-error", "Error saving user");
            }
        };

        const onDelete = (item) => {
            emit("on-delete", item)
        }

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };

        return {p_model, isDirty, onSave, onDelete, markDirty }
    }

}
</script>

<style>

</style>