<template>
    <div class="template-base">
        <Header Title="Dispenser Settings" class="div-header" :isProcessing="fetchData || isProcessing" :errorMessage="errorMessage" :processingMessage="processingMessage"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
        </div>
        <div class="select-button-container-preferences div-body" v-if="!fetchData">            
            <HotKeyColors :settings="settings" @on-save-colors="onSaveColors"/>                        
        </div>
    </div>
</template>

<script>
import Header from "../../components/Shared/Header";
import HotKeyColors from "../../components/Administrator/HotKeyColors";


import Button from "../../components/Shared/Button";
import ButtonModel from "../../models/ButtonModel";
import apiSettings from "../../api/apiSettings";
import { ref } from "vue";
import globalStatus from "../../composables/globalStatus";



export default {
    components: {
        Header,
        HotKeyColors,                
        Button
    },
    setup(props, { emit }) {
        const { fetchData, isProcessing, errorMessage, resetStatus, processingMessage, setMessage } = globalStatus();

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");

        const settings = ref(null)
        const hotkeys = ref(null)        

        const getData = async () => {
            try {
                resetStatus("Loading...");                
                settings.value = await apiSettings.getAll()                
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data"
            }
            fetchData.value = false;
        };
        getData();        

        const onSaveColors = async () => {            
            try {
                setMessage("Saving...");
                isProcessing.value = true;
                await apiSettings.saveHotkeyColors(settings.value);
            } catch (err) {
                console.error(err);
                emit("on-error", "Error saving settings");
            } finally {
                isProcessing.value = false;
            }
        };        

        return { btnExit, fetchData, isProcessing, errorMessage, processingMessage, resetStatus, settings, hotkeys, onSaveColors };
    }
};
</script>

<style>

</style>
