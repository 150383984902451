import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Welcome from "../views/Welcome.vue";
import Home from "../views/Home.vue";
import PointOfSale from "../views/PointOfSale.vue";
import Cashier from "../views/Cashier.vue";
import AdministratorCategory from "../views/Administrator/Category.vue";
import AdministratorHotkeys from "../views/Administrator/Hotkeys.vue";
import DispenserSettings from "../views/Administrator/DispenserSettings.vue";
import CustomerSettings from "../views/Administrator/CustomerSettings.vue";
import BatchPinCodes from "../views/Administrator/BatchPinCodes.vue";
import CashierSettings from "../views/Administrator/CashierSettings.vue";
import AdministratorUserRights from "../views/Administrator/UserRights.vue";
import AdministratorReporting from "../views/Administrator/Reporting.vue";
import AdministratorTaxRates from "../views/Administrator/TaxRates.vue";
import CustomerManagement from "../views/Administrator/CustomerManagement.vue";

import AdministratorInventory from "../views/Administrator/Inventory.vue";
import AdministratorMenuGroups from "../views/Administrator/MenuGroups.vue";
import AdministratorDiscounts from "../views/Administrator/Discounts.vue";

import SchedulerResources from "../views/Scheduler/Resources.vue";
import PlayerScheduler from "../views/Public/PlayerScheduler.vue";
import ViewReservation from "../views/Public/ViewReservation.vue";
import TenantManager from "../views/TenantManager.vue";


import Login from "../views/Login.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Welcome",
        component: Welcome,
    },
    {
        path: "/Home",
        name: "Home",
        component: Home,
    },
    {
        path: "/Login",
        name: "Login",
        component: Login,
    },
    {
        path: "/PointOfSale",
        name: "PointOfSale",
        component: PointOfSale,
    },
    {
        path: "/Cashier",
        name: "Cashier",
        component: Cashier,
    },
    {
        path: "/Administrator/Category",
        name: "Administrator-Category",
        component: AdministratorCategory,
    },
    {
        path: "/Administrator/Hotkeys",
        name: "Administrator-Hotkeys",
        component: AdministratorHotkeys,
    },        
    {
        path: "/Administrator/Dispenser-Settings",
        name: "Dispenser-Settings",
        component: DispenserSettings,
    },
    {
        path: "/Administrator/Batch-PinCodes",
        name: "Batch-PinCodes",
        component: BatchPinCodes,
    },
    {
        path: "/Administrator/Cashier-Settings",
        name: "Cashier-Settings",
        component: CashierSettings,
    },
    {
        path: "/Administrator/Customer-Settings",
        name: "Customer-Settings",
        component: CustomerSettings,
    },    
    {
        path: "/Administrator/Reporting",
        name: "Administrator-Reporting",
        component: AdministratorReporting,
    },
    {
        path: "/Administrator/UserRights",
        name: "Administrator-UserRights",
        component: AdministratorUserRights,
    },
    {
        path: "/Administrator/TaxRates",
        name: "Administrator-TaxRates",
        component: AdministratorTaxRates,
    },
    {
        path: "/Administrator/MenuGroups",
        name: "Administrator-Groups",
        component: AdministratorMenuGroups,
    },
    {
        path: "/Administrator/Inventory",
        name: "Administrator-Inventory",
        component: AdministratorInventory,
    },
    {
        path: "/Administrator/Discounts",
        name: "Administrator-Discounts",
        component: AdministratorDiscounts,
    },    
    {
        path: "/Administrator/Customer-Management",
        name: "Customer-Management",
        component: CustomerManagement,
    },
    {
        path: "/Scheduler/Resources",
        name: "Scheduler-Resources",
        component: SchedulerResources,
    },
    {
        path: "/Public/PlayerScheduler",
        name: "PlayerScheduler",
        component: PlayerScheduler,
    },
    {
        path: "/Public/ViewReservation/:reservationId",
        name: "ViewReservation",        
        component: ViewReservation,
        props: true
    },
    {
        path: "/TenantManager",
        name: "Tenant-Manager",
        component: TenantManager,
    },
    
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


export default router;
