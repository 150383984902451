<template>
    <div class="customer-item card-body" v-if="p_model.isVisible">
        <div type="button" class="card-header" style="display:flex; justify-content:space-between">
            <div style="">
                <span style="margin-left:5px">{{ p_model.customerNumber }} - {{ p_model.lastname }}, {{ p_model.firstname }} - {{p_model.getRelationshipText()}}</span>
            </div>
            <div>
                <button class="administrator-button" @click="onSave(p_model)" v-show="isDirty">
                    Save
                </button>
                <button class="administrator-button button-delete" @click="onDelete(p_model)">
                    Delete
                </button>
            </div>
        </div>
        <div class="customer-content">
            <div class="customer-input-container">
                <span>Last Name</span>
                <input @KeyDown="markDirty" class="customer-input-item" type="text" v-model="p_model.lastname" />
            </div>
            <div class="customer-input-container">
                <span>First Name</span>
                <input @KeyDown="markDirty" class="customer-input-item" type="text" v-model="p_model.firstname" />
            </div>
            <div class="customer-input-container">
                <span>Email</span>
                <input @KeyDown="markDirty" class="customer-input-item" type="email" v-model="p_model.email" />
            </div>
            <div class="customer-input-container">
                <span>Category</span>
                <select class="customer-input-item-select" v-model.number="p_model.categoryNumber" @change="markDirty">
                    <option v-for="category in categoryList" :key="category.categoryId" :value="category.categoryId">{{ category.categoryText }}</option>
                </select>
            </div>

            <div class="customer-input-container">
                <span>Mobile</span>
                <input @KeyDown="markDirty" class="customer-input-item" type="tel" v-model="p_model.mobile" />
            </div>

            <div class="customer-input-container">
                <span>Phone</span>
                <input @KeyDown="markDirty" class="customer-input-item" type="tel" v-model="p_model.phone" />
            </div>
            
        </div>

        <div class="customer-content">
            <div class="customer-input-container">
                <span>Address 1</span>
                <input @KeyDown="markDirty" class="customer-input-item" type="address" v-model="p_model.address" />                
            </div>            
            <div class="customer-input-container">
                <span>Address 2</span>                
                <input @KeyDown="markDirty" class="customer-input-item" type="address" v-model="p_model.address2" />
            </div>            
            <div class="customer-input-container">
                <span>City</span>
                <input @KeyDown="markDirty" class="customer-input-item" type="text" v-model="p_model.city" />
            </div>

            <div class="customer-input-container">
                <span>State</span>
                <input @KeyDown="markDirty" class="customer-input-item" type="text" v-model="p_model.state" />
            </div>

            <div class="customer-input-container">
                <span>Zip</span>
                <input @KeyDown="markDirty" class="customer-input-item" type="text" v-model="p_model.zip" />
            </div>

            <div class="customer-input-container">
                <span>Country</span>
                <input @KeyDown="markDirty" class="customer-input-item" type="text" v-model="p_model.country" />
            </div>
            
        </div>

        <div class="customer-content">
            <div class="customer-input-container">
                <span>Notes</span>
                <textarea @KeyDown="markDirty" style="width:300px" class="customer-input-item" type="text" v-model="p_model.notes" />
            </div>

            <div class="customer-input-container">
                <span>Balance</span>
                <div>
                    <input @KeyDown="markDirty" @change="markDirty" class="customer-input-item-short administrator-input-item-numeric" style="margin-right:2px" type="text" readonly v-model="formatedBalamce" />                    
                    <button class="administrator-button" @click="onCreditAdd" >
                        Add
                    </button>
                </div>
            </div>
            

            <div class="customer-input-container">
                <span>Range Card</span>
                <div>                    
                    <button class="administrator-button" @click="onRangeCardAssign" style="width:60px">Assign</button>                    
                    <button class="administrator-button button-delete" @click="onRangeCardRemove" style="width:60px" :disabled="!p_model.hasRangeCard" >Remove</button>
                </div>
            </div>
            <div class="customer-input-container">
                <span>Children</span>
                <div>                    
                    <button class="administrator-button" @click="onChildAdd" style="width:60px" :disabled="p_model.getRelationship() === CustomerRelationshipTypes.Child">Add</button>                    
                    <button class="administrator-button" @click="onChildrenShow" style="width:60px" :disabled="p_model.getRelationship() !== CustomerRelationshipTypes.Parent">Show</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Utilities from "../../common/utilities";
import { computed } from '@vue/runtime-core';
import { CustomerRelationshipTypes } from "../../common/enums";

export default {
    props: ["itemmodel", "categoryList"],
    setup(props, { emit }) {
        const p_model = ref(props.itemmodel);
        const isDirty = ref(false);

        const onSave = async (item) => {
            console.debug(item);
            isDirty.value = false;
            emit("on-dirty", false);
            try {
                //await apiUser.update(p_model.value);
            } catch (err) {
                console.error(err);
                emit("on-error", "Error saving user");
            }
        };

        const onDelete = (item) => {
            emit("on-delete", item);
        };

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };
        
        const formatedBalamce = computed(() => {                    
            return (Utilities.toCurrency(p_model.value.balance))
        });

        return { p_model, isDirty, onSave, onDelete, markDirty, formatedBalamce, CustomerRelationshipTypes };
    }
};
</script>

<style>
.customer-content {
    display: flex;
    justify-content: space-between;
}

.customer-item {
    width: 98%;
    min-width: 1040px;
    margin-left: 1%;
    margin-right: 1%;
}
.customer-input-container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin: 10px;
}

.customer-input-item {
    width: 150px;
}

.customer-input-item-short {
    width: 75px;
}

.customer-input-item-select {
    width: 161px;
}

</style>
