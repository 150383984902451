<template>
    <div class="cart">
        <CartTotals :cartItems="cartItems" :taxes="taxes" style="height: 25%;width:100%"/>

        <CartHeader style="height: 5%;width:100%" />
        <div style="height:68%;overflow-y: scroll;">
            <div v-for="cartItem in cartItems" :key="cartItem.inventoryItem.inventoryId">
                <CartItem
                    :cartItem="cartItem"
                    :taxes="taxes"
                    @delete-cart-item="removeCartItem"
                    @on-increment="onIncrement"
                    @on-decrement="onDecrement"
                />
            </div>
        </div>        
    </div>
</template>

<script>
import CartItem from "../PointOfSale/CartItem";
import CartHeader from "../PointOfSale/CartHeader";
import CartTotals from "../PointOfSale/CartTotals";

export default {
    props: ["cartItems", "taxes"],
    components: { CartItem, CartHeader, CartTotals },
    setup(props, { emit }) {
        const removeCartItem = (id) => {
            emit("delete-cart-item", id);
        };

        const onIncrement = (id) => {
            emit("on-increment", id);
        };

        const onDecrement = (id) => {
            emit("on-decrement", id);
        };

        return { removeCartItem, onIncrement, onDecrement };
    }, // setup
};
</script>

<style>
.cart {
    background-color: white;
    color: Black;
    position: relative;
    border-right-style: solid;
    border-right-width: 1px;
}
</style>
