<template>
    <div class="backdrop" @click.self="close">
        <div class="modal">
            <div class="customer-list-header">
                <!-- <button class="customer-list-close" @click="close">Close</button> -->
            </div>
            <div class="customer-list-grid-container">
                <table class="customer-list-grid">
                    <thead>
                        <tr>
                            <th class="customer-list-grid-th customer-list-open">&nbsp;</th>
                            <th class="customer-list-grid-th customer-list-customer-number">Customer Number</th>
                            <th class="customer-list-grid-th customer-list-last-name">Last Name</th>
                            <th class="customer-list-grid-th customer-list-first-name">First Name</th>
                            <th class="customer-list-grid-th customer-list-balance">Balance</th>
                            <th class="customer-list-grid-th customer-list-mobile">Mobile</th>
                            <th class="customer-list-grid-th customer-list-email">Email</th>
                            <th class="customer-list-grid-th customer-list-view">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr :class="{ 'customer-list-row-selected': selectedCustomNumber == customer.CustomerNumber }" v-for="customer in customers" :key="customer.CustomerNumber" @click="onRowselect(customer)" :id="'_row' + customer.CustomerNumber">
                            <td class="customer-list-grid-td customer-list-open">
                                <button class="customer-list-select" @click="selectCustomer(customer)">Select</button>
                            </td>
                            <td class="customer-list-grid-td customer-list-customer-number">{{ customer.CustomerNumber }}</td>
                            <td class="customer-list-grid-td customer-list-last-name">{{ customer.LastName }}</td>
                            <td class="customer-list-grid-td customer-list-first-name">{{ customer.FirstName }}</td>
                            <td class="customer-list-grid-td customer-list-balance">{{ customer.Balance }}</td>
                            <td class="customer-list-grid-td customer-list-mobile">{{ customer.Mobile }}</td>
                            <td class="customer-list-grid-td customer-list-email">{{ customer.Email }}</td>
                            <td class="customer-list-grid-td customer-list-view">
                                <button class="customer-list-select" @click="viewCustomer(customer)">View</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

import CustomerModel from "../../models/CustomerModel";

export default {
    setup(props, { emit }) {
        const close = () => {
            emit("close");
        };

        const onRowselect = (customer) => {
            console.debug(customer);
            selectedCustomNumber.value = customer.CustomerNumber;
        };

        const selectedCustomNumber = ref(null);

        var customerArray = [
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(1, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(2, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(3, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(4, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(5, "Washington", "George", "georige.washington@prez.com"),
            new CustomerModel(6, "Washington", "George", "georige.washington@prez.com")
        ];

        const customers = ref(customerArray);

        const selectCustomer = (customer) => {
            emit("on-select", customer);
        };

        const viewCustomer = (customer) => {
            emit("on-view", customer);
        };

        return { close, onRowselect, customers, selectedCustomNumber, selectCustomer, viewCustomer };
    }
};
</script>

<style>
.customer-list-grid-container {
    overflow: auto;
    height: 100%;
}

.customer-list-header {
    display: flex;
    align-content: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: green;
    height: 10%;
}

.customer-list-close {
    background: red;
    height: 10%;
    width: 60px;
}

.customer-list-select {
    background: cornflowerblue;
    height: 30px;
    width: 60px;
}

.customer-list-grid {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
    background: white;
}

.customer-list-grid-th {
    border: 1px solid black;
    border-collapse: collapse;
    background: silver;
}

.customer-list-grid-td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px;
    color: black;
}

.customer-list-row-selected {
    /* background: cornflowerblue; */
}
</style>
