<template>
    <button class="select-button" @click="onClick()" :disabled="disable" v-bind:style="[disable ? { 'background': 'var(--input-background-disabled)', color: 'var(--input-color-disabled)' } : { 'background': buttonModel.buttonColor }]">
        {{ buttonModel.buttonCaption }}
    </button>
</template>

<script>
import { useRouter } from "vue-router";

import { ButtonTypes } from "../../common/enums";

export default {
    props: ["buttonModel", "disable"],
    setup(props, { emit }) {
        const router = useRouter();

        const onClick = () => {            
            switch (props.buttonModel.buttonType) {
                case ButtonTypes.Route:
                    router.push({ name: props.buttonModel.route });
                    break;
                case ButtonTypes.Other:                    
                    if (props.buttonModel.action !== null) props.buttonModel.action();
                    break;
                case ButtonTypes.Clickable:                    
                    emit("on-clicked", props.buttonModel);
                    break;
                default:                    
                    emit("on-clicked", props.buttonModel);
                    break;
            }
        };

        return { onClick };
    }
};
</script>

<style scoped>
.select-button {
    width: 125px;
    height: 125px;    
    background-color: var(--button-backgroud);
    color: var(--button-color);
    /* margin: 0.5vh 0.5vw 0.5vh 0.5vw; */
    margin: 5px;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    padding: 0px;    
    font-size: 10pt;
    font-weight: 200;
    font-family: "Roboto", sans-serif;
    border-radius: 6px;
}
</style>
