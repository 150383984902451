<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="new-tenant">
            <div style="display:flex;justify-content:center">New Site</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="tenant-input-container">
                    <span>Site Name</span>
                    <input class="tenant-input-item" type="text" v-model="tenantName" maxlength="75"/>
                </div>                
                <div class="tenant-input-container">
                    <span>Site Address</span>
                    <input class="tenant-input-item" type="text" v-model="address" maxlength="300"/>
                </div>                
                <div class="new-tenant-button-bar">
                    <button class="new-tenant-button" @click="onCreate">
                        Create Tenant
                    </button>
                    <button class="new-tenant-button" @click="onCancel" ref="btnCancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";


export default {    
    setup(prop, { emit }) {        
        const btnCancel = ref(null);
        const tenantName = ref('');
        const address = ref('');        

        const onCancel = () => {
            emit("on-cancel");
        };

        const onCreate = () => {            
            emit("on-createTenant", tenantName.value, address.value);
        };

        onMounted(() => {
            btnCancel.value.focus();
        });

        return { btnCancel, onCancel, onCreate, tenantName, address };
    }
};
</script>

<style>
.new-tenant {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -205px;
    margin-left: -255px;
    width: 500px;
    height: 200px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.new-tenant-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.new-tenant-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
}
</style>
