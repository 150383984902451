import axios from "axios";
import PortalLicenseModel from "../models/PortalLicenseModel";

export default class apiTenant {
    static async getAll(): Promise<Array<PortalLicenseModel>> {
        let models = new Array<PortalLicenseModel>();

        let respone = await axios.post("api/Tenant/getAll");
        respone.data.models.forEach((item: any) =>
            models.push(PortalLicenseModel.from(item))
        );        
        return models;
    }

    static async save(siteLicense: PortalLicenseModel): Promise<void> {
        let respone = await axios.post("api/Tenant/save", {
            model: siteLicense,
        });
    }

    static async create(tenantName: String, tenantAddress : String): Promise<PortalLicenseModel> {
        let respone = await axios.post("api/Tenant/create", {
            tenantName: tenantName,            
            tenantAddress: tenantAddress            
        });

        return PortalLicenseModel.from(respone.data.models);
    }
}
