<template>
    <div class="category-item card-body" v-if="p_category.isVisible">
        <div type="button" class="card-header">
            {{ p_category.categoryText }}
        </div>
        <div class="content">
            <div class="administrator-button-bar">
                <button class="administrator-button" @click="onSave(p_category)" v-show="isDirty">
                    Save
                </button>
                <!-- <button class="administrator-button" style="background:crimson" @click="onDelete(p_category)">
                    Delete
                </button> -->
            </div>

            <div class="category-input-container">
                <span>Category Name</span>
                <input class="category-input-item" type="text" v-model="p_category.categoryText" @KeyDown="markDirty"/>
            </div>
            
            <div class="category-input-container" style="justify-content: flex-start">
                <input type="checkbox" :id="'chkCatIsPin' + p_category.isPinCategory" v-model="p_category.isPinCategory" @change="onPinCategoryChecked"/>
                <label :for="'chkCatIsPin' + p_category.isPinCategory">Pin Code Category</label>
            </div>

            <div class="category-input-container">
                <span>Pin Expiration Days</span>
                <input class="category-input-item administrator-input-item-numeric" type="number" min="0" max="100" step="1" v-model.number="p_category.expirationsDays" @change="markDirty" :disabled="!p_category.isPinCategory"/>
            </div>

            <div class="category-input-container">
                <input type="checkbox" :id="'chkMon' + p_category.categoryId" v-model="p_category.activeMonday" @change="markDirty" :disabled="p_category.isPinCategory"/>
                <label :for="'chkMon' + p_category.categoryId">M</label>

                <input type="checkbox" :id="'chkTue' + p_category.categoryId" v-model="p_category.activeTuesday" @change="markDirty" :disabled="p_category.isPinCategory"/>
                <label :for="'chkTue' + p_category.categoryId">T</label>

                <input type="checkbox" :id="'chkWed' + p_category.categoryId" v-model="p_category.activeWednesday" @change="markDirty" :disabled="p_category.isPinCategory"/>
                <label :for="'chkWed' + p_category.categoryId">W</label>

                <input type="checkbox" :id="'chkThu' + p_category.categoryId" v-model="p_category.activeThursday" @change="markDirty" :disabled="p_category.isPinCategory"/>
                <label :for="'chkThu' + p_category.categoryId">T</label>

                <input type="checkbox" :id="'chkFri' + p_category.categoryId" v-model="p_category.activeFriday" @change="markDirty" :disabled="p_category.isPinCategory"/>
                <label :for="'chkFri' + p_category.categoryId">F</label>

                <input type="checkbox" :id="'chkSat' + p_category.categoryId" v-model="p_category.activeSaturday" @change="markDirty" :disabled="p_category.isPinCategory"/>
                <label :for="'chkSat' + p_category.categoryId">S</label>

                <input type="checkbox" :id="'chkSun' + p_category.categoryId" v-model="p_category.activeSunday" @change="markDirty" :disabled="p_category.isPinCategory"/>
                <label :for="'chkSun' + p_category.categoryId">S</label>
            </div>
            <div class="category-input-container" style="justify-content: flex-start">
                <input type="checkbox" :id="'chkCatForeverCat' + p_category.categoryId" v-model="isForever" @change="markDirty" :disabled="p_category.isPinCategory"/>
                <label :for="'chkCatForeverCat' + p_category.categoryId">Forever</label>
            </div>
            <div class="category-input-container">
                <span>Valid From Date</span>
                <input class="category-input-item" type="datetime-local" v-model="p_category.fromDateTime" @change="markDirty" :disabled="isForever || p_category.isPinCategory"/>
            </div>
            <div class="category-input-container">
                <span>Valid To Date</span>
                <input class="category-input-item" type="datetime-local" v-model="p_category.toDateTime" @change="markDirty" :disabled="isForever ||p_category.isPinCategory"/>
            </div>
            <div class="category-input-container">
                <span>Discount %</span>
                <input class="category-input-item administrator-input-item-numeric" type="number" min="0" max="100" step="1" v-model.number="p_category.discount" @change="markDirty" :disabled="p_category.isPinCategory"/>
            </div>
            <div class="category-input-container">
                <span>Bonus %</span>
                <input class="category-input-item administrator-input-item-numeric" type="number" min="0" max="500" step="1" v-model.number="p_category.bonus" @change="markDirty" :disabled="p_category.isPinCategory"/>
            </div>
            <div class="category-input-container">
                <span>Minutes Before Reuse</span>
                <input class="category-input-item administrator-input-item-numeric" type="number" min="0" max="527040" step="1" v-model.number="p_category.delayBeforeNextUse" @change="markDirty" :disabled="p_category.isPinCategory"/>
            </div>
            <div class="category-input-container">
                <span>Use per Day</span>
                <input class="category-input-item administrator-input-item-numeric" type="number" min="0" max="999999" step="1" v-model.number="p_category.usePerDay" @change="markDirty" :disabled="p_category.isPinCategory"/>
            </div>
            <HappyHourItem title="Happy Hour 1" :categoryId="p_category.categoryId" :happyHour="p_category.happyHours[0]" @on-change="markDirty" :isPinCategory="p_category.isPinCategory"/>
            <HappyHourItem title="Happy Hour 2" :categoryId="p_category.categoryId" :happyHour="p_category.happyHours[1]" @on-change="markDirty" :isPinCategory="p_category.isPinCategory"/>
            <HappyHourItem title="Happy Hour 3" :categoryId="p_category.categoryId" :happyHour="p_category.happyHours[2]" @on-change="markDirty" :isPinCategory="p_category.isPinCategory"/>
            <HappyHourItem title="Happy Hour 4" :categoryId="p_category.categoryId" :happyHour="p_category.happyHours[3]" @on-change="markDirty" :isPinCategory="p_category.isPinCategory"/>
        </div>
    </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import HappyHourItem from "./HappyHourItem";
import apiCategory from "../../api/apiCategory";


export default {
    props: ["category"],
    components: { HappyHourItem },
    setup(props, {emit}) {           
        const p_category = ref(props.category);
        const isDirty = ref(false);       
        const isForever = ref(false) 

        const onSave = async (item) => {                        
            emit("on-serverprocessing", true);
            try {
                console.log(item)
                await apiCategory.save(item);
                isDirty.value = false;
                emit("on-dirty", false);                
            } catch (err) {
                console.error(err);              
                emit("on-error", "Error saving category");
            } finally {
                emit("on-serverprocessing", false);
            }
        };

        const onDelete = (item) => {
            emit("on-delete", item);
        };

        const markDirty = () => {            
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };

        watchEffect(() => {
            isForever.value =  ((new Date(p_category.value.fromDateTime).getTime() == new Date("1900-01-01T00:00:00").getTime())
                    && (new Date(p_category.value.toDateTime).getTime() == new Date("2200-01-01T00:00:00").getTime()))
        });


        const onPinCategoryChecked = () =>
        {
            
            if (p_category.value.isPinCategory) {
                p_category.value.activeMonday =
                p_category.value.activeTuesday =
                p_category.value.activeWednesday =
                p_category.value.activeThursday =
                p_category.value.activeFriday =
                p_category.value.activeSaturday =
                p_category.value.activeSunday =
                false;
            }

            markDirty();
        }

        

        return { p_category, onSave, onDelete, markDirty , isDirty, isForever, onPinCategoryChecked};
    }
};
</script>

<style>
.category-item {
    width: 400px;    
    min-width: 100px;        
}
.category-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.category-input-item {
    width: 200px;
}
</style>
