<template>
    <div class="setting-item">
        <div class="setting-header">
            <div>Category Settings</div>
            <div class="setting-header-bar"><hr /></div>
            <div style="display:flex;flex-direction:column">
                <div class="setting-input-container">
                    <span class="setting-name">Default Category:</span>
                    <select class="hotkey-input-item-select" v-model.number="p_settings.defaultCategoryId" @change="onSave">
                        <option v-for="category in categoryList" :key="category.categoryId" :value="category.categoryId">{{ category.categoryText }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    components: {},
    props: ["settings", "categoryList"],
    setup(props, { emit }) {
        const p_settings = ref(props.settings);

        const onSave =  () => {
            emit("on-save-preferences");                    
        };

        return { p_settings, onSave };
    }
};
</script>

<style>
.hot-key-colors-button {
    width: 120px;
}
</style>
