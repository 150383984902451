<template>
    <div class="template-base">
        <Header Title="Preferences" class="div-header" />
        <div class="menu-bar">
            <Button class="x-smaller-button"
                v-for="hotkey in hotkeys"
                :key="hotkey.KeyCaption"
                :buttonModel="hotkey"
            />
        </div>
        <div class="select-button-container-preferences div-body">
            <div class="setting-item">
                <div class="setting-header">
                    <div>Reporting</div>
                    <div><hr /></div>
                    <div style="display:flex;flex-direction:column">                        

                        <div class="setting-input-container">
                            <span class="setting-name">Web Report Theme:</span>
                            <select class="setting-input-item">
                                <option id="0">Light</option>
                                <option id="0">Modern</option>
                                <option id="0">Holi Ai</option>
                            </select>
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">Date Range:</span>
                            <select class="setting-input-item">
                                <option id="0">Today</option>
                                <option id="0">Yesterday</option>
                                <option id="0">This Week</option>
                                <option id="0">This Month</option>
                                <option id="0">This Year</option>
                                <option id="0">Custom</option>
                            </select>
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">Start</span>
                            <input class="setting-input-item" type="date" />
                        </div>

                        <div class="setting-input-container">
                            <span class="setting-name">End</span>
                            <input class="setting-input-item" type="date" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../../components/Shared/Header";

import Button from "../../components/Shared/Button";
import ButtonModel from "../../models/ButtonModel";

export default {
    components: {
        Header,
        Button,
    },
    setup() {
        const hotkeys = [
            ButtonModel.CreateRoute("Exit", "Home"),
            ButtonModel.CreateClick("Standard Reports"),
            ButtonModel.CreateClick("End of Shift Report"),
        ];

        return { hotkeys };
    },
};
</script>

<style></style>
