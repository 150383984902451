<template>
    <div class="profile-backdrop" @click.self="close">
        <div class="profile-body">
            <div class="profile-title">{{ tenant }}<hr/></div>
            <div class="profile-display">{{ displayName }}<br/>{{ email }}</div>                        
            <button class="button-link" @click="signOut"><hr/>Sign Out</button>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import router from "@/router";
import PortalUserModel from "../../models/PortalUserModel"

export default {
    setup(props, { emit }) {

        var portalUser = PortalUserModel.fromToken();

        const displayName = ref("");
        const email = ref("");
        const tenant = ref("");

        if (portalUser !== "") {
            displayName.value = portalUser.displayName;
            email.value = portalUser.email;
            tenant.value = portalUser.tenant;
        }

        const close = () => {            
            emit("close");
        };

        const signOut = async () => {            
            localStorage.clear();
            router.replace("/Login");            
        };

        return { close, displayName, email, tenant, signOut };
    }
};
</script>

<style>

.profile-backdrop {
    top: 0;
    position: fixed;
    background: rgba(255, 255, 255, 0);
    width: 100%;
    height: 100%;
}

.profile-body {        
    background-color: white;
    border-radius: 10px;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 170px;
    left: 100%;
    margin-top: -110px;
    margin-left: -243px;
    width: 200px;
    height: 200px;    
    border-radius: 10px;
    padding: 10px;
    border: 1px solid var(--card-body-border);
}

.profile-title {
    font-size: 13pt;
    color: black;    
    text-align: center;
}

.profile-display{
    font-size: 11pt;
    color: black;    
    text-align: center;
}
</style>
