<template>
    <div class="setting-item">
        <div class="setting-header">
            <div>Pin Code Ticket Settings</div>
            <div class="setting-header-bar"><hr /></div>
            <div style="display:flex;flex-direction:column">                
                
                <div class="setting-input-container">
                    <span class="setting-name">Pin Code Digits:</span>
                    <input @change="onSave" class="setting-input-item administrator-input-item-numeric" type="number" min="3" max="6" step="1" v-model.number="p_settings.pincodeDigits" />
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Pin Expiration Days:</span>
                    <input @change="onSave" class="setting-input-item administrator-input-item-numeric" type="number" min="0" max="9999" step="1" v-model.number="p_settings.pincodeExpirationDays" :disabled="p_settings.pincodeExpirationDays < 1" />
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">                        
                        <input @change="onSave" type="checkbox" id="chkEndOfDay" v-model="p_settings.endOfDayExpiration" :checked="p_settings.pincodeExpirationDays < 1"  />
                        <label for="chkEndOfDay">End Of Day</label>
                    </span>
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Ticket Font %:</span>
                    <input @change="onSave" class="setting-input-item administrator-input-item-numeric" type="number" min="1" max="200" step="1" v-model.number="p_settings.ticketFont" />
                </div>

                <div class="setting-input-container">
                    <span class="setting-name">Ticket Header:</span>                
                    <textarea @change="onSave" class="setting-input-item" name="message" rows="10" cols="30" v-model="p_settings.ticketHeader"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "vue";

export default {
    props: ["settings"],
    components: {},
    setup(props, { emit }) {
        const p_settings = ref(props.settings);

        const onSave =  () => {
            emit("on-save-pincode");                    
        };

        return { p_settings , onSave};
    }
};
</script>

<style>

</style>
