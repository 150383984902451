<template>
    <div class="hotkey-item card-body" v-if="p_hotkey.isVisible">
        <Div type="button" class="card-header">
            {{ p_hotkey.keyCaption }}
        </Div>
        <div class="content">
            <div class="administrator-button-bar">
                <button class="administrator-button" @click="onSave(p_hotkey)" v-show="isDirty">
                    Save
                </button>
                <button class="administrator-button" style="background:crimson" @click="onDelete(p_hotkey)">
                    Delete
                </button>
            </div>
            <div class="hotkey-input-container">
                <span>Key Caption:</span>
                <input class="hotkey-input-item" type="text" v-model="p_hotkey.keyCaption" @KeyDown="markDirty" />
            </div>

            <div class="hotkey-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'chkUseCaption' + p_hotkey.keyId" v-model="p_hotkey.useCaption" />
                <label :for="'chkUseCaption' + p_hotkey.keyId">Use Caption</label>
            </div>

            <div class="hotkey-input-container">
                <span>Key Type:</span>
                <select class="hotkey-input-item-select" v-model="p_hotkey.hotKeyType" @change="markDirty">
                    <option v-bind:value="HotKeyTypes.PinTicketSmall">Pin Ticket Small</option>
                    <option v-bind:value="HotKeyTypes.PinTicketMedium">Pin Ticket Medium</option>
                    <option v-bind:value="HotKeyTypes.PinTicketLarge">Pin Ticket Large</option>
                    <option v-bind:value="HotKeyTypes.PinTicketKingSize">Pin Ticket King</option>
                    <option v-bind:value="HotKeyTypes.RangeCard">Range Card</option>
                    <option v-bind:value="HotKeyTypes.RangeCardAuto">Range Card Auto</option>
                    <option v-bind:value="HotKeyTypes.SaleItem">Sale Item</option>
                    <option v-bind:value="HotKeyTypes.PinTicketCustom">Pin Ticket Custom</option>
                    <option v-bind:value="HotKeyTypes.PinTicketCategory">Pin Ticket Category</option>
                    <option v-bind:value="HotKeyTypes.TurnstileEntry">Turnstile Entry</option>
                </select>
            </div>

            <div class="hotkey-input-container">
                <span>Category:</span>
                <select class="hotkey-input-item-select" v-model.number="p_hotkey.categoryId" @change="markDirty">
                    <option v-for="category in categoryList" :key="category.categoryId" :value="category.categoryId">{{ category.categoryText }}</option>
                </select>
            </div>

            <div class="hotkey-input-container">
                <span>Price:</span>
                <input @KeyDown="markDirty" @change="markDirty" class="hotkey-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_hotkey.priceDisplay" />
            </div>

            <div class="hotkey-input-container">
                <span>To Account:</span>
                <input @KeyDown="markDirty" @change="markDirty" class="hotkey-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_hotkey.toAccountDisplay" />
            </div>

            <div class="hotkey-input-container">
                <span>Custom Balls:</span>
                <input
                    @KeyDown="markDirty"
                    @change="markDirty"
                    class="hotkey-input-item administrator-input-item-numeric"
                    type="number"
                    min="-999999"
                    max="999999"
                    step="1"
                    v-model.number="p_hotkey.customBalls"
                    :disabled="p_hotkey.hotKeyType !== HotKeyTypes.PinTicketCustom"
                />
            </div>

            <div class="hotkey-input-container">
                <span>Hot Key:</span>
                <input @KeyDown="markDirty" @change="markDirty" class="hotkey-input-item administrator-input-item-numeric" type="text" v-model="p_hotkey.hotKeyCmd" />
            </div>

            <div class="hotkey-input-container">
                <span style="align-self: center">Custom Color:</span>
                <input
                    class="hotkey-input-item"
                    style="width:50px"
                    type="color"
                    v-model="p_hotkey.keyColor"
                    @change="
                        onColorChange();
                        markDirty();
                    "
                />
            </div>

            <div class="hotkey-input-container">
                <span>Display Order:</span>
                <input @KeyDown="markDirty" @change="markDirty" class="hotkey-input-item administrator-input-item-numeric" type="number" min="-999999" max="999999" step="1" v-model.number="p_hotkey.displayOrder" />
            </div>

            <div class="hotkey-input-container" style="justify-content: flex-start">
                <input
                    @change="markDirty"
                    type="checkbox"
                    :id="'chkUpdateCategory' + p_hotkey.keyId"
                    v-model="p_hotkey.updateCustomerCategory"
                    :disabled="p_hotkey.hotKeyType !== HotKeyTypes.RangeCard && p_hotkey.hotKeyType !== HotKeyTypes.RangeCardAuto"
                />
                <label :for="'chkUpdateCategory' + p_hotkey.keyId">Update customer category</label>
            </div>

            <div class="hotkey-input-container" style="justify-content: flex-start">
                <input @change="markDirty" type="checkbox" :id="'chkactive' + p_hotkey.keyId" v-model="p_hotkey.displayed" />
                <label :for="'chkactive' + p_hotkey.keyId">Active</label>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { HotKeyTypes } from "../../common/enums";
import apiHotkey from "../../api/apiHotkey";

export default {
    props: ["hotkey", "categoryList"],
    components: {},
    setup(props, { emit }) {
        const p_hotkey = ref(props.hotkey);
        const originalColor = p_hotkey.value.keyColor;
        const isDirty = ref(false);        

        const onSave = async (item) => {
            isDirty.value = false;
            emit("on-dirty", false);        
            try {
                await apiHotkey.save(item);
            } catch (err) {
                console.error(err);
                emit("on-error", "Error saving hot key");
            }
        };

        const onDelete = (item) => {
            emit("on-delete", item);
        };

        const onColorChange = () => {
            if (originalColor !== p_hotkey.value.keyColor) p_hotkey.value.defaultColor = false;
        };

        const markDirty = () => {
            if (!isDirty.value) {
                emit("on-dirty", true);
            }
            isDirty.value = true;
        };

        watchEffect(() => {
            switch (p_hotkey.value.hotKeyType) {
                case HotKeyTypes.PinTicketSmall:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    break;
                case HotKeyTypes.PinTicketMedium:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    break;
                case HotKeyTypes.PinTicketLarge:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    break;
                case HotKeyTypes.PinTicketKingSize:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    break;
                case HotKeyTypes.PinTicketCustom:
                    p_hotkey.value.updateCustomerCategory = false;
                    break;

                case HotKeyTypes.RangeCard:
                    p_hotkey.value.customBalls = 0;
                    break;
                case HotKeyTypes.RangeCardAuto:
                    p_hotkey.value.customBalls = 0;
                    break;

                case HotKeyTypes.SaleItem:
                    p_hotkey.value.customBalls = 0;
                    p_hotkey.value.updateCustomerCategory = false;
                    break;
            }
        });

        return {
            HotKeyTypes,
            onSave,
            onDelete,
            p_hotkey,
            onColorChange,
            isDirty,
            markDirty
        };
    }
};
</script>

<style>
.hotkey-item {
    width: 300px;
    min-width: 100px;
}
.hotkey-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.hotkey-input-item {
    width: 150px;
}

.hotkey-input-item-select {
    width: 161px;
}
</style>
