import HappyHourModel from "./HappyHourModel"

export default class CategoryModel{
    categoryId: string = "";
    categoryText: string = ""    
    fromDateTime: Date = new Date();
    toDateTime: Date = new Date();
    discount: number = 0;
    bonus: number = 0;        
    expirationsDays: number = 1;
    delayBeforeNextUse: number = 0;
    usePerDay: number = 0;        
    status: string = "";    
    happyHours: HappyHourModel[] = [];
    isVisible: boolean = true;
    isPinCodeCategory: boolean = false;
    

    activeMonday: boolean = false;
    activeTuesday: boolean = false;
    activeWednesday: boolean = false;
    activeThursday: boolean = false;
    activeFriday: boolean = false;
    activeSaturday: boolean = false;
    activeSunday: boolean = false;
    

    static from(json: string): CategoryModel {        
        return Object.assign(new CategoryModel(), json);        
    }
}