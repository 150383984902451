<template>
    <div class="backdrop" @click.self="close">
        <div class="modal">
            <div class="customer-detail-grid-container">
                Customer Details
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup(props, { emit }) {
        const close = () => {
            emit("close");
        };

        return { close };
    },
};
</script>

<style></style>
