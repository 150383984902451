import SlotModel from "./SlotModel"


export default class ReserveSlotResponse {
    reserved : boolean = false;
    message : string = ""    


    static from(json: string): ReserveSlotResponse {
        let model = Object.assign(new ReserveSlotResponse(), json);
        return model;
    }
}