import { ButtonTypes } from "../common/enums";
import Utilities from "../common/utilities"

export default class ButtonModel {
    buttonCaption: string = "";
    buttonType: ButtonTypes = ButtonTypes.Clickable;
    buttonColor: string = "";
    route: string = "";
    tag: any = null;
    action: any = null;
    
    
    public static CreateRoute(buttonCaption: string, route: string) : ButtonModel {
        return ButtonModel.Create(buttonCaption, "", ButtonTypes.Route, route, null)
    }

    public static CreateRouteColor(buttonCaption: string, route: string, buttonColor: string) : ButtonModel {
        return ButtonModel.Create(buttonCaption, buttonColor, ButtonTypes.Route, route, null)
    }

    public static CreateClick(buttonCaption: string) : ButtonModel {
        return ButtonModel.Create(buttonCaption, "", ButtonTypes.Clickable, "", null)
    }

    public static CreateClickColor(buttonCaption: string, buttonColor: string) : ButtonModel {
        return ButtonModel.Create(buttonCaption, buttonColor, ButtonTypes.Clickable, "", null)
    }


    public static CreateAction(buttonCaption: string, action: any) : ButtonModel {
        return ButtonModel.Create(buttonCaption, "", ButtonTypes.Other, "", action)
    }

    public static CreateActionColor(buttonCaption: string, action: any, buttonColor: string) : ButtonModel {
        return ButtonModel.Create(buttonCaption, buttonColor, ButtonTypes.Other, "", action)
    }

    public static Create(buttonCaption: string, buttonColor: string, buttonType: ButtonTypes, route: string, action: any) : ButtonModel {
        let button = new ButtonModel();
        button.buttonCaption = buttonCaption;
        button.buttonType = buttonType;
        button.route = route;
        button.buttonColor = buttonColor === "" ? "var(--button-backgroud)" : buttonColor;
        button.action = action;
        return button;

    }

    

}