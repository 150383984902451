<template>
  <div class="template-base">
        <MessageBox :caption="messageBox_caption" :message="messageBox_Message" :tag="messageBox_Tag" v-if="messageBox_show" @on-no="onMessageBoxNo" @on-yes="onMessageBoxYes_DeleteResource" />
        <Header Title="Resource Scheduler Configuration" class="div-header" :isProcessing="fetchData" :errorMessage="errorMessage" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="AddResource" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" />
            </div>            
        </div>
        <div class="select-button-container div-body">
            <div class="select-button-container">
                <ResourceSetting v-for="resource in resources" :key="resource.resourceId" @on-error="onError" @on-delete="onDelete" @on-dirty="onDirty" />
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import Header from "../../components/Shared/Header";
// import ResourceSetting from "../../components/Scheduler/ResourceSetting";
import Button from "../../components/Shared/Button";
import MessageBox from "../../components/Shared/MessageBox";
import ButtonModel from "../../models/ButtonModel";
import { ref } from "vue";
import globalStatus from "../../composables/globalStatus";

export default {
components: { Header, Button, MessageBox },
    setup() {
        const { fetchData, errorMessage, resetStatus } = globalStatus();

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");
        const btnAdd = ButtonModel.CreateClick("New Resource");

        var resources_base = [];
        const resources = ref([]);
        const searchNameText = ref("");

        const searchKeyType = ref(-1);

        const messageBox_caption = ref("");
        const messageBox_Message = ref("");
        const messageBox_Tag = ref(null);
        const messageBox_show = ref(false);

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const updateFilter = () => {
            resources.value.forEach((item) => {
              console.debug(item)
                // var isVisible = item.taxName.includes(searchNameText.value);
                // if (searchKeyType.value != -1) isVisible = isVisible && item.hotKeyType == searchKeyType.value;

                // item.isVisible = isVisible;
            });
        };

        const onError = (err) => {
            console.error(err);
            errorMessage.value = err;
        };

        // const getData = async () => {
        //     try {
        //         resetStatus();
        //         resources_base = await apiTax.getAll();
        //         resources.value = resources_base;
        //     } catch (err) {
        //         console.error(err);
        //         errorMessage.value = "Error fetching data";
        //     }
        //     fetchData.value = false;
        // };
        const getData = () => {
            try {
                resetStatus("Loading...");
                resources_base = []
                resources_base.push("1")
                resources_base.push("2")
                resources_base.push("3")
                resources.value = resources_base;
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data";
            }
            fetchData.value = false;
        };
        getData();

        const AddResource = async () => {
            try {
                resetStatus("Creating Resource...");
                //var newResource = await apiTax.create();
                var newResource = "";
                resources.value.unshift(newResource);
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error creating new resource";
            }
            fetchData.value = false;
        };

        const onDelete = async (item) => {
            messageBox_caption.value = "Delete Resource";
            messageBox_Message.value = "Are you sure you want to delete resource " + item + "?";
            messageBox_Tag.value = item;
            messageBox_show.value = true;            
        };

        const onMessageBoxYes_DeleteResource = async (item) => {
            try {
                resetStatus("Deleting Resource...");
                //await apiTax.delete(item.taxId);
                var index = resources.value.indexOf(item);
                if (index > -1) resources.value.splice(index, 1);
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error deleting resource";
            }
            fetchData.value = false;

            onMessageBoxNo();
        };

        const onMessageBoxNo = () => {
            messageBox_show.value = false;
            messageBox_caption.value = "";
            messageBox_Message.value = "";
            messageBox_Tag.value = null;
        };

        return {
            btnExit,
            btnAdd,
            resources,            
            fetchData,
            errorMessage,
            onError,
            searchNameText,
            updateFilter,
            searchKeyType,        
            AddResource,
            onDelete,
            messageBox_caption,
            messageBox_Message,
            messageBox_Tag,
            messageBox_show,
            onMessageBoxNo,
            onMessageBoxYes_DeleteResource,
            onDirty
        };
    }
}
</script>


<style>

</style>