<template>
    <div class="setting-item">
        <div class="setting-header">
            <div>Customer Required Fields</div>
            <div class="setting-header-bar"><hr /></div>
            <div style="display:flex;flex-direction:column">
                <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="LastName" @change="onSave" v-model="p_settings.requireLastName" />
                    <label for="LastName">Last Name</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="FirstName" @change="onSave" v-model="p_settings.requireFirstName" />
                    <label for="FirstName">First Name</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="Email" @change="onSave" v-model="p_settings.requireEmail" />
                    <label for="Email">E-Mail</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="Phone" @change="onSave" v-model="p_settings.requirePhone" />
                    <label for="Phone">Phone</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="Mobile" @change="onSave" v-model="p_settings.requireCellPhone" />
                    <label for="Mobile">Mobile</label>
                </span>
            </div>            

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="Address" @change="onSave" v-model="p_settings.requireAddress" />
                    <label for="Address">Address</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="City" @change="onSave" v-model="p_settings.requireCity" />
                    <label for="City">City</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="State" @change="onSave" v-model="p_settings.requireState" />
                    <label for="State">State</label>
                </span>
            </div>

            <div class="setting-input-container">
                <span>
                    <input type="checkbox" id="Zip" @change="onSave" v-model="p_settings.requireZip" />
                    <label for="Zip">Zip</label>
                </span>
            </div>
            </div>
        </div>


    </div>
</template>

<script>
import { ref } from "vue";

export default {
    props: ["settings"],
    components: {},
    setup(props, { emit }) {
        const p_settings = ref(props.settings);

        const onSave =  () => {
            emit("on-save-preferences");                    
        };

        return { p_settings , onSave};
    },
};
</script>

<style></style>
