

export default class MenuGroupModel {
    menuGroupId: string = "";
    menuGroupName: string = "";
    parentId: string = "";
    menuLevel: number = 0;
    menuOrder: number = 0;

    static from(json: string): MenuGroupModel {
        return Object.assign(new MenuGroupModel(), json);
    }    
}
