<template>
    <div class="entry-bar">
        <!-- <form> -->
        <p>
            Search Item:
            <input ref="inputItem" @blur="onBlur" type="text" style="width:25vw;margin-left:10px" required v-model="searchItem" @keyup="onEnterItem" :list="[searchItem !== null && searchItem.length > 0 ? 'browsers' : '']" />
        </p>
        <datalist id="browsers">
            <option v-for="item in inventoryList" :key="item.inventoryId" :value="item.inventoryName"> </option>
        </datalist>
        <!-- </form> -->
    </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";

import InventoryModel from "../../models/InventoryModel";

export default {
    props: ["inventoryList"],
    setup(props, { emit }) {
        const inputItem = ref(null);
        const searchItem = ref(null);

        const onBlur = () => {
            setTimeout(function() {                
                if (inputItem.value !== null) inputItem.value.focus();
            }, 10);
        };

        const onEnterItem = (e) => {
            if (e.keyCode === 13) {
                var foundItem = InventoryModel.FindItem(props.inventoryList, searchItem.value);
                if (foundItem !== null) {                    
                    emit("item-selected", foundItem);
                    searchItem.value = "";
                }
            }
        };

        onMounted(() => {
            inputItem.value.focus();
        });

        watch(searchItem, () => {            
            var foundItem = InventoryModel.FindItem(props.inventoryList, searchItem.value);
            if (foundItem !== null) {                
                emit("item-selected", foundItem);
                searchItem.value = "";
            }
        });

        return { onBlur, inputItem, searchItem, onEnterItem };
    }
};
</script>

<style>
.entry-bar {
    background-color: var(--main-background);
    width: 100%;
    padding-left: 5px;
}
</style>
