import SettingModel from "../models/SettingModel";
import PiReloadOptionModel from "../models/PiReloadOptionModel"
import PiBucketModel from "../models/PiBucketModel"
import axios from "axios";

export default class apiSettings {

    static async getAll(): Promise<SettingModel> {
        let model = new SettingModel();

        let respone = await axios.post("api/Setting/GetAll");
        model = SettingModel.from(respone.data)            

        return model;
    }

    static async saveCardReload(reloadModels: PiReloadOptionModel[]): Promise<void> {
        let respone = await axios.post("api/Setting/SaveCardReload", {
            piReloadOptions: reloadModels,
        });
    }

    static async saveKioskBucketSize(bucketModels: PiBucketModel[]): Promise<void> {        
        let respone = await axios.post("api/Setting/SaveBucketSize", {
            piBuckets: bucketModels,
        });
    }

    static async savePreference(settingModel: SettingModel): Promise<void> {
        let respone = await axios.post("api/Setting/savePreference", {
            settingModel
        });
    }

    static async savePincode(settingModel: SettingModel): Promise<void> {
        let respone = await axios.post("api/Setting/savePincode", {
            settingModel
        });
    }

    static async saveHotkeyColors(settingModel: SettingModel): Promise<void> {
        let respone = await axios.post("api/Setting/saveHotkeyColors", {
            settingModel
        });
    }

    

    
}
