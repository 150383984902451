import MenuGroupModel from "../models/MenuGroupModel";
import axios from "axios";

export default class apiMenuGroup {

    static async getAll(): Promise<Array<MenuGroupModel>> {
        let models = new Array<MenuGroupModel>();

        let respone = await axios.post("api/MenuGroup/getAll");
        respone.data.models.forEach((item: any) =>
            models.push(MenuGroupModel.from(item))
        );        

        return models;
    }

    static async create(parentId: string): Promise<MenuGroupModel> {       
        let respone = await axios.post("api/MenuGroup/create", {
            ParentId: parentId,
        });
        let model = MenuGroupModel.from(respone.data.model)            

        return model;
    }

    static async save(model: MenuGroupModel): Promise<void> {
        let respone = await axios.post("api/MenuGroup/save", {
            model: model,
        });
    }

    static async delete(menuGroupId: string): Promise<void> {
        let respone = await axios.post("api/MenuGroup/delete", {
            MenuGroupId: menuGroupId ,
        });
    }

    static async swap(menuGroupId1: string, menuGroupId2: string): Promise<void> {
        let respone = await axios.post("api/MenuGroup/swap", {
            MenuGroupId1: menuGroupId1 ,
            MenuGroupId2: menuGroupId2 ,
        });
    }
}
