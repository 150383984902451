<template>
  <div class="template-base">
        <Header Title="User Management" class="div-header" :isProcessing="fetchData" :errorMessage="errorMessage" :processingMessage="processingMessage" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="toggleNewUser" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" />
            </div>
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;">
            <div class="select-button-container">
                <UserItem v-for="user in users" :key="user.email" :itemmodel="user" @on-error="onError" @on-dirty="onDirty"/>
            </div>
        </div>
        <NewUser v-if="newUser_show" @on-cancel="toggleNewUser" @on-createUser="createUser" :tenantId="null" :tenantName="tenantName" />
    </div>
</template>

<script>
import Header from "../../components/Shared/Header";
import Button from "../../components/Shared/Button";
import ButtonModel from "../../models/ButtonModel";
import UserItem from "../../components/Administrator/UserItem";
import { onBeforeRouteLeave } from "vue-router";
import { ref } from '@vue/reactivity';
import globalStatus from "../../composables/globalStatus";
import apiUser from "../../api/apiUser";
import NewUser from "../../components/Shared/NewUser";
import PortalUserModel from "../../models/PortalUserModel";

export default {
    components: { Header, Button, UserItem, NewUser },
    setup() {

        const { fetchData, errorMessage, resetStatus, processingMessage } = globalStatus();
        
        var users_base = [];
        const users = ref([]);
        const searchNameText = ref("");
        const newUser_show = ref(false);

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");
        const btnAdd = ButtonModel.CreateClick("New User");

        var portalUser = PortalUserModel.fromToken();        
        const tenantName = ref(portalUser.tenant)        

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const getData = async () => {
            try {
                resetStatus("Loading...");
                users_base = await apiUser.getAll();
                users.value = users_base;                
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data";
            }
            fetchData.value = false;
        };
        getData();


        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const updateFilter = () => {                        
            users.value.forEach((item) => {
                item.isVisible = item.displayName.toLowerCase().includes(searchNameText.value.toLowerCase());
            });
        };

        const onError = (err) => {
            console.error(err);
            errorMessage.value = err;
        };
        

        const toggleNewUser = () => {            
            newUser_show.value = !newUser_show.value;            
        };

        const createUser = async (newUser) => {
            try {
                resetStatus("Creating User...");
                var newUserModel = await apiUser.add(newUser);                
                users.value.push(newUserModel)                
                newUser_show.value = false;
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error creating user";
            }
            fetchData.value = false;
        };

        return {fetchData, errorMessage, resetStatus, btnExit, btnAdd, users, onDirty, updateFilter, onError, createUser, toggleNewUser, newUser_show, tenantName, searchNameText, processingMessage}
    }

}
</script>

<style>

</style>