<template>
    <div class="template-base">
        <Header Title="Select Cashier" class="div-header" :isProcessing="fetchData" :errorMessage="errorMessage" :processingMessage="processingMessage" />
        <div class="cashier-container div-body">
            <div class="cashier-container" v-if="!fetchData && !errorMessage.length">
                <Button v-for="hotkey in hotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
            </div>
        </div>
    </div>
</template>

<script>
import apiHotkey from "../api/apiHotkey";
import Button from "../components/Shared/Button";
import Header from "../components/Shared/Header";
import { ref } from "vue";
import globalStatus from "../composables/globalStatus";
import ButtonModel from "@/models/ButtonModel";
import { ButtonTypes } from "@/common/enums";
import apiSettings from "../api/apiSettings";


export default {
    components: { Button, Header },
    setup() {
        const { fetchData, errorMessage, resetStatus, processingMessage } = globalStatus();

        const hotkeys = ref([]);
        const getHotkeys = async () => {
            try {
                resetStatus("Loading...");
                var settings = await apiSettings.getAll();

                var keys = await apiHotkey.getActive();
                keys.forEach((element) => {
                    var button = ButtonModel.Create(element.keyCaption, element.keyColor, ButtonTypes.Clickable, "", null);
                    button.tag = element;
                    hotkeys.value.push(button);
                });

                hotkeys.value.push(ButtonModel.CreateClickColor("Lookup Pin", settings.systemColor));
                hotkeys.value.push(ButtonModel.CreateClickColor("Lookup Customer", settings.systemColor));
                hotkeys.value.push(ButtonModel.CreateClickColor("Cash Log", settings.systemColor));
                hotkeys.value.push(ButtonModel.CreateRouteColor("Log Off", "Home", settings.systemColor));
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data";
            }
            fetchData.value = false;
        };

        getHotkeys();

        return { hotkeys, fetchData, errorMessage, processingMessage };
    } // setup
};
</script>

<style>
.cashier-container {
    display: flex;
    align-content: flex-start;
    background-color: var(--main-background);
    flex-direction: row;
    flex-wrap: wrap;
    /* overflow-y: scroll; */
    height: 100%;
}
</style>
