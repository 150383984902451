<template>
  <div class="cart-item-header">
        <div class="cart-item-header-quantity cart-item-header-common">
            Qty
        </div>
        <div class="cart-item-header-name cart-item-header-common">Item</div>
        <div class="cart-item-header-price cart-item-header-common">Price</div>
        <div class="cart-item-header-tax cart-item-header-common">Tax</div>
        <div class="cart-item-header-total cart-item-header-common">Total</div>
        <div class="cart-item-header-end">Delete</div>        
    </div>
</template>

<script>
export default {

}
</script>

<style>
.cart-item-header {    
    background-color: grey;
    border-top: 1px solid black;
    display: flex;
    flex-direction: row;
}

.cart-item-header-common {
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    display: flex;
    border-right: 1px solid black;
}

.cart-item-header-end {
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    display: flex;    
}

.cart-item-header-quantity {
    display: flex;
    align-items: center;
    width: 7vw;
}

.cart-item-header-qty {
    width: 2vw;
    text-align: right;
    padding-left: 5px;
    padding-right: 5px;
}

.cart-item-header-button {
    height: 40px;
    width: 40px;
    background-color: silver;
    padding: 2px;
    margin-left: 2px;
}

.cart-item-header-button-delete {
    height: 40px;
    width: 40px;
    background-color: red;
    padding: 2px;
    margin-left: 2px;
}

.cart-item-header-name {
    width: 20vw;
}

.cart-item-header-price {
    width: 4vw;
    text-align: right;    
    justify-content: flex-end;
}

.cart-item-header-tax {
    width: 4vw;
    text-align: right;
    justify-content: flex-end;
}

.cart-item-header-total {
    width: 4vw;
    text-align: right;
    justify-content: flex-end;
}
</style>
