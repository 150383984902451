<template>
    <router-view />
</template>

<script>
import { onBeforeMount } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import PortalUserModel from "./models/PortalUserModel"
import Utilities from "./common/utilities"

export default {
    setup() {
        const router = useRouter();

        onBeforeMount(() => {
            var token = localStorage.getItem("token");            
            if (token !== null) {
                var portalUser = PortalUserModel.fromToken();                

                if (!portalUser.IsValid()) {
                    localStorage.clear();
                    router.replace("/Login");
                }

                Utilities.startKeepAlive();
                router.replace("/Home");
            }
            else
                router.replace("/Login");
        });
    }
};
</script>

<style>
:root {
    --main-background: white;
    --main-color: black;

    --card-header-color: black;
    --card-header-backgroud: #f6f6f6;

    --card-body-color: black;
    --card-body-backgroud: white;
    --card-body-border: #38b449;

    --delete-color: white;
    --delete-backgroud: #b4292b;

    --menu-bar-backgroud: #eee;
    --menu-bar-color: black;

    --drop-area-backgroud: #bbbbbb;

    --dragable-area-backgroud: #0a4fb9;
    --dragable-area-color: white;

    --button-backgroud: white;
    /* --button-color: #262626; */
    --button-color: #0a4fb9;

    --collapsible-bar-backgroud: gray;
    --collapsible-bar-color: black;

    --collapsible-bar-backgroud-hover: grey;
    --collapsible-bar-color-hover: black;

    --input-background: white;
    --input-color: black;
    --input-background-disabled: grey;
    --input-color-disabled: black;

    --input-border-color: #38b449;

    --anchor-color: #0a4fb9;
    --menuGroup-backgroud: #2196e2;
}
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-family: "Arial", "Times New Roman", Times, serif;   */
    /* font-family: "Nunito", "Arial", "Times New Roman", Times, serif; */
    font-family: "Roboto", sans-serif;
    /* font-family: 'Open Sans', sans-serif;     */
    margin: 0px;
    min-width: 800px;
    min-height: 600px;
    background: var(--main-background);
    color: var(--main-color);
}

.template-base {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.template-base-not-100 {
    display: flex;
    flex-direction: column;    
}

.select-button-container {
    display: flex;
    align-content: flex-start;
    background-color: var(--main-background);
    flex-direction: row;
    flex-wrap: wrap;
    /* overflow-y: scroll; */
}

.div-header {
    height: 5%;
}

.div-body {
    height: 95%;
}

.select-container {
    background-color: var(--main-background);
    align-content: flex-start;
}

.select-container-columns {
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}
.select-container-rows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
}

button {
    border: 0px;
    background: var(--button-backgroud);
    box-shadow: none;
}

datalist {
    display: none;
}

.backdrop {
    top: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
}

.modal {
    width: 70%;
    height: 50%;
    margin: 100px auto;
    background: white;
    /* border-radius: 10px; */
}

.card-header {
    background-color: var(--card-header-backgroud);
    color: var(--card-header-color);
    padding-top: 10px;
    height: 30px;
    width: 100%;
    border-bottom: 1px solid var(--card-body-border);
    text-align: center;
    font-size: 15px;
}

.card-body {
    background-color: var(--card-body-backgroud);
    color: var(--card-body-color);
    margin: 5px 10px;
    border: 1px solid var(--card-body-border);
}

/* Style the button that is used to open and close the collapsible content */
.collapsible {
    background-color: var(--collapsible-bar-backgroud);
    color: var(--collapsible-bar-color);
    cursor: pointer;
    padding: 10px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    margin: auto;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active,
.collapsible:hover {
    background-color: var(--collapsible-bar-backgroud-hover);
    color: var(--collapsible-bar-color-hover);
}

button:focus {
    outline: 5;
    border: 1px solid black;
}

input[type="text"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    /* text-align: right; */
    padding: 5px;
}

input[type="email"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    /* text-align: right; */
    padding: 5px;
}

input[type="address"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    /* text-align: right; */
    padding: 5px;
}

input[type="tel"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    /* text-align: right; */
    padding: 5px;
}

input[type="text"]:disabled {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-disabled);
    color: var(--input-color-disabled);
    /* text-align: right; */
    padding: 5px;
}

input[type="password"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    /* text-align: right; */
    padding: 5px;
}

input[type="password"]:disabled {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-disabled);
    color: var(--input-color-disabled);
    /* text-align: right; */
    padding: 5px;
}

input[type="number"] {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    padding: 5px;
}

input[type="number"]:disabled {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-disabled);
    color: var(--input-color-disabled);
    padding: 5px;
}

select {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
    padding: 5px;
    /* for Chrome */
    /* -webkit-appearance: none; */
    /* for Firefox */
    /* -moz-appearance: none; */
}

select:disabled {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-disabled);
    color: var(--input-color-disabled);
    padding: 5px;
    /* for Chrome */
    /* -webkit-appearance: none; */
    /* for Firefox */
    /* -moz-appearance: none; */
}

.button-delete {
    background: var(--delete-backgroud);
    color: var(--delete-color);
}

.smaller-button {
    max-width: 100px;
    max-height: 100px;
}

.x-smaller-button {
    max-width: 100px;
    max-height: 50px;
}

textarea {
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background);
    color: var(--input-color);
}

textarea:focus,
input:focus,
select:focus {
    outline: none;
}

.menu-bar {
    background: var(--menu-bar-backgroud);
    color: var(--menu-bar-color);
    display: flex;
    padding: 5px 5px;
    border-bottom: 2px black solid;
}

.administrator-button {
    padding: 5px;
    margin-right: 10px;
    width: 50px;
    border: 1px solid black;
}

.administrator-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
    height: 30px;
}

.administrator-input-item-numeric {
    text-align: right;
}

a {
    text-decoration: none;
    color: var(--anchor-color);
}

.button-link {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: var(--anchor-color);
    text-decoration: none;
    cursor: pointer;
}
</style>
