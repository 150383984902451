<template>
    <div class="template-base">
        <Header Title="Customer Category" class="div-header" :isProcessing="fetchData || isProcessing" :errorMessage="errorMessage" :processingMessage="processingMessage" />
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="AddCategory" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Filter Name:</div>
                <input type="text" v-model="searchNameText" :onKeyUp="updateFilter" />
            </div>
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;">
            <div class="select-button-container" v-if="!fetchData">
                <CategoryItem v-for="category in categories" :key="category.categoryId" :category="category" @on-error="onError" @on-delete="onDelete" @on-dirty="onDirty" @on-serverprocessing="onServerProcessing" />
            </div>
        </div>
    </div>
</template>

<script>
import apiCategory from "../../api/apiCategory";
import Header from "../../components/Shared/Header";
import CategoryItem from "../../components/Administrator/CategoryItem";
import Button from "../../components/Shared/Button";
import ButtonModel from "../../models/ButtonModel";
import { ref } from "vue";
import globalStatus from "../../composables/globalStatus";
import { onBeforeRouteLeave } from 'vue-router';

export default {
    components: { Header, CategoryItem, Button },
    setup() {
        const { fetchData, isProcessing, errorMessage, resetStatus, processingMessage, setMessage } = globalStatus();

        var dirtyCount = 0;

        const searchNameText = ref("");        

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");
        const btnAdd = ButtonModel.CreateClick("New Category");

        var categories_base = [];
        const categories = ref([]);
        const getCategories = async () => {
            try {
                resetStatus("Loading...");
                categories_base = await apiCategory.getAll();
                categories.value = categories_base;
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error fetching data";
            }
            fetchData.value = false;
        };
        getCategories();
 
        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };

        const onServerProcessing = (process) => {
            isProcessing.value = process
            if (process) setMessage("Saving...");
        };

        const updateFilter = () => {                                    
            categories.value.forEach((item) => {                
                item.isVisible = item.categoryText.toLowerCase().includes(searchNameText.value.toLowerCase());
            });
        };

        const AddCategory = async () => {
            try {
                resetStatus("Creating Category...");
                var newCategory = await apiCategory.create();
                categories.value.unshift(newCategory);
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error creating new hotkey";
            }
            fetchData.value = false;
        };

        const onError = (message) => {
            errorMessage.value = message;
        }

        return { btnExit, btnAdd, categories, fetchData, errorMessage, processingMessage, onDirty, searchNameText, updateFilter, AddCategory, onServerProcessing, onError, isProcessing };
    }
};
</script>

<style></style>
