<template>
    <div style="margin-top:20px;border-style: solid;border-width: 1px;">
        <button type="button" class="collapsible" @click="toggleExpand()">{{ title }}</button>
        <div class="content" :style="[collapsed ? { display: 'none' } : { display: 'block' }]">
            <div class="category-input-container" style="margin-top:20px">
                <span>{{ title }}</span>
            </div>
            <div class="category-input-container">
                <input type="checkbox" :id="'chkMon' + categoryId+p_happyHour.slot" v-model="p_happyHour.activeMonday" @change="markDirty"/>
                <label :for="'chkMon' + categoryId+p_happyHour.slot">M</label>

                <input type="checkbox" :id="'chkTue' + categoryId+p_happyHour.slot" v-model="p_happyHour.activeTuesday" @change="markDirty"/>
                <label :for="'chkTue' + categoryId+p_happyHour.slot">T</label>

                <input type="checkbox" :id="'chkWed' + categoryId+p_happyHour.slot" v-model="p_happyHour.activeWednesday" @change="markDirty"/>
                <label :for="'chkWed' + categoryId+p_happyHour.slot">W</label>

                <input type="checkbox" :id="'chkThu' + categoryId+p_happyHour.slot" v-model="p_happyHour.activeThursday" @change="markDirty"/>
                <label :for="'chkThu' + categoryId+p_happyHour.slot">T</label>

                <input type="checkbox" :id="'chkFri' + categoryId+p_happyHour.slot" v-model="p_happyHour.activeFriday" @change="markDirty"/>
                <label :for="'chkFri' + categoryId+p_happyHour.slot">F</label>

                <input type="checkbox" :id="'chkSat' + categoryId+p_happyHour.slot" v-model="p_happyHour.activeSaturday" @change="markDirty"/>
                <label :for="'chkSat' + categoryId+p_happyHour.slot">S</label>

                <input type="checkbox" :id="'chkSun' + categoryId+p_happyHour.slot" v-model="p_happyHour.activeSunday" @change="markDirty"/>
                <label :for="'chkSun' + categoryId+p_happyHour.slot">S</label>

            </div>
            <div class="category-input-container" style="justify-content: flex-start">                
                <input type="checkbox" :id="'chkCatForeverCat' + categoryId+p_happyHour.slot" v-model="isAllDay" @change="markDirty"/>
                <label :for="'chkCatForeverCat' + categoryId+p_happyHour.slot">All Day</label>
            </div>
            <div class="category-input-container">
                <span>From Time</span>
                <input class="category-input-item" type="time" v-model="p_happyHour.fromTimeString" @change="markDirty" :disabled="isAllDay"/>
            </div>
            <div class="category-input-container">
                <span>To Time</span>
                <input class="category-input-item" type="time" v-model="p_happyHour.toTimeString" @change="markDirty" :disabled="isAllDay"/>
            </div>
            <div class="category-input-container">
                <label style="width:50px"></label>
                <label style="width:50px">Price</label>
                <label style="width:50px">Balls</label>
            </div>
            <div class="category-input-container">
                <label style="width:50px">Small</label>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[0].balls" @KeyDown="markDirty" :disabled="isPinCategory"/>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[0].price" @KeyDown="markDirty" :disabled="isPinCategory"/>
            </div>
            <div class="category-input-container">
                <label style="width:50px">Medium</label>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[1].balls" @KeyDown="markDirty" :disabled="isPinCategory"/>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[1].price" @KeyDown="markDirty" :disabled="isPinCategory"/>
            </div>
            <div class="category-input-container">
                <label style="width:50px">Large</label>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[2].balls" @KeyDown="markDirty" :disabled="isPinCategory"/>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[2].price" @KeyDown="markDirty" :disabled="isPinCategory"/>
            </div>
            <div class="category-input-container">
                <label style="width:50px">King</label>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[3].balls" @KeyDown="markDirty" :disabled="isPinCategory"/>
                <input style="width:50px" class="administrator-input-item-numeric" type="number" v-model.number="p_happyHour.ballPrices[3].price" @KeyDown="markDirty" :disabled="isPinCategory"/>
            </div>
        </div>
    </div>
</template>

<script>
import { watchEffect, ref } from "vue";
export default {
    props: ["title", "happyHour", "categoryId", "isPinCategory"],
    setup(props, { emit }) {
        const collapsed = ref(true);
        const p_happyHour = ref(props.happyHour);
        const isAllDay = ref(false)        

        const toggleExpand = () => {
            collapsed.value = !collapsed.value;
        };

        const markDirty = () => {
            emit("on-change", true);
        };        

        watchEffect(() => {
            isAllDay.value =((p_happyHour.value.fromTimeString == "00:00") && (p_happyHour.value.toTimeString == "23:59"))
        });

        return {p_happyHour,  collapsed, toggleExpand, markDirty, isAllDay };
    }
};
</script>

<style></style>
