

export default class SelectCheckboxItem {
    id: string = "";
    name: string = "";   
    checked: boolean = false;
    
    constructor(id: string, name: string, checked: boolean) {
        this.id = id;
        this.name = name;
        this.checked = checked;
      }
}
