<template>
    <div class="template-base">
        <Header Title="Dispenser Settings" class="div-header" :isProcessing="fetchData || isProcessing" :errorMessage="errorMessage" :processingMessage="processingMessage"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
        </div>
        <div class="select-button-container-preferences div-body" v-if="!fetchData">
            <KioskCardReload :settings="settings" :hotkeys="hotkeys" @on-serverprocessing="onServerProcessing"/>
            <KioskBucketSize :settings="settings" :hotkeys="hotkeys" @on-serverprocessing="onServerProcessing"/>
            <PinCodeTicket :settings="settings" @on-save-pincode="onSavePincode"/>                                   
        </div>
    </div>
</template>

<script>
import Header from "../../components/Shared/Header";
import KioskCardReload from "../../components/Administrator/KioskCardReload";
import KioskBucketSize from "../../components/Administrator/KioskBucketSize";
import PinCodeTicket from "../../components/Administrator/PinCodeTicket";


import Button from "../../components/Shared/Button";
import ButtonModel from "../../models/ButtonModel";
import apiSettings from "../../api/apiSettings";
import { ref } from "vue";
import globalStatus from "../../composables/globalStatus";
import apiHotkey from "../../api/apiHotkey";



export default {
    components: {
        Header,
        KioskCardReload,
        KioskBucketSize,
        PinCodeTicket,        
        Button
    },
    setup(props, { emit }) {
        const { fetchData, isProcessing, errorMessage, resetStatus, processingMessage, setMessage } = globalStatus();

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");

        const settings = ref(null)
        const hotkeys = ref(null)        

        const getData = async () => {
            try {
                resetStatus("Loading...");
                hotkeys.value = await apiHotkey.getAll()                
                settings.value = await apiSettings.getAll()                
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data"
            }
            fetchData.value = false;
        };
        getData();

        const onSavePreferences = async () => {            
            try {                
                setMessage("Saving...");                
                isProcessing.value = true;
                await apiSettings.savePreference(settings.value);
            } catch (err) {
                console.error(err);
                emit("on-error", "Error saving settings");
            } finally {
                isProcessing.value = false;
            }
        };

        const onSavePincode = async () => {
            try {
                setMessage("Saving...");
                isProcessing.value = true;
                await apiSettings.savePincode(settings.value);
            } catch (err) {
                console.error(err);
                emit("on-error", "Error saving settings");
            } finally {
                isProcessing.value = false;
            }
        };        

        const onServerProcessing = (process) => {
            isProcessing.value = process
            if (process) setMessage("Saving...");
        };

        return { btnExit, fetchData, isProcessing, errorMessage, processingMessage, resetStatus, settings, hotkeys, onSavePreferences, onSavePincode, onServerProcessing};
    }
};
</script>

<style>
.select-button-container-preferences {
    display: flex;
    align-content: flex-start;
    background-color: var(--main-background);
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: scroll;
}

.setting-item {
    width: 100%;
    min-width: 100px;
    /* background-color: lightblue; */
    margin: 5px;
    border-radius: 8px;
    padding: 5px;
    font-size: 12pt;
    font-weight: 200;
    font-family: "Roboto", sans-serif;
    align-self: flex-start;
}
.setting-input-container {
    display: flex;
    /* justify-content: space-between; */
    margin: 10px;
    padding-left: 20%;
}

.setting-input-item {
    width: 200px;
}

.setting-name {
    width: 200px;
}

.setting-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
    width: 100%;
}

.setting-header-bar {
    width: 100%;
}
</style>
