<template>
    <div class="template-base">
        <Header Title="Batch Pin Codes" class="div-header" :isProcessing="fetchData || isProcessing" :errorMessage="errorMessage" :processingMessage="processingMessage"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
        </div>
        <div class="select-button-container-preferences div-body" v-if="!fetchData">            
            <PinCodeBatch />                                           
        </div>
    </div>
</template>

<script>
import Header from "../../components/Shared/Header";
import PinCodeBatch from "../../components/Administrator/PinCodeBatch";


import Button from "../../components/Shared/Button";
import ButtonModel from "../../models/ButtonModel";
import globalStatus from "../../composables/globalStatus";



export default {
    components: {
        Header,
        PinCodeBatch,        
        Button
    },
    setup() {
        const { fetchData, isProcessing, errorMessage, resetStatus, processingMessage } = globalStatus();

        const btnExit = ButtonModel.CreateRoute("Exit", "Home");



        return { btnExit, fetchData, isProcessing, errorMessage, processingMessage, resetStatus};
    }
};
</script>

<style>

</style>
