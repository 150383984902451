import { HotKeyTypes } from "../common/enums";
import HotkeyModel from "../models/HotkeyModel";
import axios from "axios";

export default class apiHotkey {
    static async getActive(): Promise<Array<HotkeyModel>> {
        let models = new Array<HotkeyModel>();

        try {
            let respone = await axios.post("api/Hotkey/getActive");
            respone.data.models.forEach((item: any) =>
                models.push(HotkeyModel.from(item))
            );
            

            return models;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    static async getActiveByType(hotkeyTypes: Array<HotKeyTypes>): Promise<Array<HotkeyModel>> {
        let models = new Array<HotkeyModel>();

        try {
            let respone = await axios.post("api/Hotkey/getActiveByType", {
                hotkeyTypes: hotkeyTypes
            });
            respone.data.models.forEach((item: any) =>
                models.push(HotkeyModel.from(item))
            );            

            return models;
        } catch (err) {
            console.error(err);
            throw err;
        }
    }

    static async getAll(): Promise<Array<HotkeyModel>> {
        let models = new Array<HotkeyModel>();

        let respone = await axios.post("api/Hotkey/getAll");
        respone.data.models.forEach((item: any) =>
            models.push(HotkeyModel.from(item))
        );

        return models;
    }

    static async create(): Promise<HotkeyModel> {
        let respone = await axios.post("api/Hotkey/create");
        let model = HotkeyModel.from(respone.data.model)    

        return model;
    }

    static async save(hotkey: HotkeyModel): Promise<void> {
        let respone = await axios.post("api/Hotkey/save", {
            model: hotkey,
        });
    }

    static async delete(hotkeyId: string): Promise<void> {
        let respone = await axios.post("api/Hotkey/delete", {
            ModelId: parseInt(hotkeyId),
        });
    }
}
