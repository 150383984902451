import CategoryListModel from "../models/CategoryListModel";
import CategoryModel from "../models/CategoryModel";
import axios from 'axios';

export default class apiCategory {

    static async getList() : Promise<Array<CategoryListModel>>  {                
        let models = new Array<CategoryListModel>();

        let respone = await axios.post('api/Category/getList')
        respone.data.categoryList.forEach((item : any) => models.push(CategoryListModel.from(item)))                        

        return models;
    }

    static async getAll() : Promise<Array<CategoryModel>>  {    
        let models = new Array<CategoryModel>();

        let respone = await axios.post('api/Category/GetAll')
        respone.data.categories.forEach((item : any) => models.push(CategoryModel.from(item)))        
        return models;
    }

    static async create(): Promise<CategoryModel> {
        let respone = await axios.post("api/Category/create");
        let model = CategoryModel.from(respone.data.model)    

        return model;
    }

    static async save(category: CategoryModel): Promise<void> {        
        let respone = await axios.post("api/Category/save", {
            model: category,
        });
    }

} 