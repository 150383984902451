<template>
    <div class="template-base" style="overflow-y:scroll">
        <Header Title="Select Portal" class="div-header" />

        <div style="padding-left:10px" v-if="superHotkeys.length > 0"><h3>Super User Settings</h3></div>
        <hr style="width:99%" v-if="superHotkeys.length > 0" />
        <div v-if="superHotkeys.length > 0">
            <Button v-for="hotkey in superHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>

        <div style="padding-left:10px" v-if="appHotkeys.length > 0"><h3>Applications</h3></div>
        <div v-if="appHotkeys.length > 0">
            <Button v-for="hotkey in appHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>

        <div style="padding-left:10px" v-if="systemHotkeys.length > 0"><h3>System Configuration</h3></div>
        <hr style="width:99%" v-if="systemHotkeys.length > 0" />
        <div v-if="systemHotkeys.length > 0">
            <Button v-for="hotkey in systemHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>

        <div style="padding-left:10px" v-if="cashierHotkeys.length > 0"><h3>Cashier Configuration</h3></div>
        <hr style="width:99%" v-if="cashierHotkeys.length > 0" />
        <div v-if="cashierHotkeys.length > 0">
            <Button v-for="hotkey in cashierHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>

        <div style="padding-left:10px" v-if="posHotkeys.length > 0"><h3>Point of Sale Configuration</h3></div>
        <hr style="width:99%" v-if="posHotkeys.length > 0" />
        <div v-if="posHotkeys.length > 0">
            <Button v-for="hotkey in posHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>

        <div style="padding-left:10px" v-if="schedulerHotkeys.length > 0"><h3>Scheduler Configuration</h3></div>
        <hr style="width:99%" v-if="schedulerHotkeys.length > 0" />
        <div v-if="schedulerHotkeys.length > 0">
            <Button v-for="hotkey in schedulerHotkeys" :key="hotkey.KeyCaption" :buttonModel="hotkey" />
        </div>
        
    </div>
</template>

<script>
//import axios from 'axios';
//import {HotKeyTypes} from "../common/enums";
//import HotkeyModel from "../models/HotkeyModel";
import ButtonModel from "../models/ButtonModel";
import Button from "../components/Shared/Button";
import Header from "../components/Shared/Header";
import { ref } from "@vue/reactivity";
import PortalUserModel from "../models/PortalUserModel";

export default {
    components: { Button, Header },
    setup() {
        var portalUser = PortalUserModel.fromToken();

        const _portalUser = ref(portalUser);

        const showMe = ref(false);

        const appHotkeys = [];
        const posHotkeys = [];
        const schedulerHotkeys = [];
        const systemHotkeys = [];
        const cashierHotkeys = [];
        const superHotkeys = [];


        if (!portalUser.isSuperUser) {
            appHotkeys.push(ButtonModel.CreateRoute("Cashier", "Cashier"));

            if (portalUser.enablePos) {
                appHotkeys.push(ButtonModel.CreateRoute("Point Of Sale", "PointOfSale"));
            }

            if (portalUser.enablePos && portalUser.isAdmin) {
                posHotkeys.push(ButtonModel.CreateRoute("Inventory", "Administrator-Inventory"));
                posHotkeys.push(ButtonModel.CreateRoute("Tax Rates", "Administrator-TaxRates"));
                posHotkeys.push(ButtonModel.CreateRoute("Menu Groups", "Administrator-Groups"));
                posHotkeys.push(ButtonModel.CreateRoute("Discounts", "Administrator-Discounts"));
            }

            if (portalUser.enableScheduler) {
                appHotkeys.push(ButtonModel.CreateRoute("Schedule Sheet", "Schedule Sheet"));
            }

            if (portalUser.enableScheduler && portalUser.isAdmin) {
                schedulerHotkeys.push(ButtonModel.CreateRoute("Resources", "Scheduler-Resources"));
            }

            if (portalUser.isAdmin) {
                appHotkeys.push(ButtonModel.CreateRoute("Customers", "Customer-Management"));
                appHotkeys.push(ButtonModel.CreateRoute("Batch Pin Codes", "Batch-PinCodes"));
                appHotkeys.push(ButtonModel.CreateRoute("Reporting", "Administrator-Reporting"));
                systemHotkeys.push(ButtonModel.CreateRoute("Dispenser Settings", "Dispenser-Settings"));
                systemHotkeys.push(ButtonModel.CreateRoute("Customer Settings", "Customer-Settings"));
                systemHotkeys.push(ButtonModel.CreateRoute("User Management", "Administrator-UserRights"));

                systemHotkeys.push(ButtonModel.CreateRoute("Categories", "Administrator-Category"));
                cashierHotkeys.push(ButtonModel.CreateRoute("Hot Keys", "Administrator-Hotkeys"));
                cashierHotkeys.push(ButtonModel.CreateRoute("Cashier Settings", "Cashier-Settings"));
            }
        }
        else         
        {
            superHotkeys.push(ButtonModel.CreateRoute("Site Management", "Tenant-Manager"));
            superHotkeys.push(ButtonModel.CreateRoute("Pi Management", "Pi-Manager"));
        }

        return { showMe, appHotkeys, systemHotkeys, cashierHotkeys, posHotkeys, schedulerHotkeys, superHotkeys, _portalUser };
    }
};
</script>

<style scoped></style>
