<template>
    <div class="template-base">
        <Header Title="Customers Management" class="div-header" :isProcessing="fetchData || isProcessing" :errorMessage="errorMessage" :processingMessage="processingMessage"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />
            <Button class="x-smaller-button" :buttonModel="btnAdd" @on-clicked="Add" />
            <Button class="x-smaller-button" :buttonModel="btnImport" @on-clicked="Import" />
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Search by</div>
                <select class="customer-input-item-select">
                    <option>Customer number</option>
                    <option>Email</option>
                    <option>Last Name</option>
                    <option>First Name</option>
                    <option>Mobile</option>
                    <option>Phone</option>
                    <option>Range Card</option>
                </select>
            </div>
            <div style="display:flex;align-items:flex-start; flex-direction:column;margin: 0px 5px">
                <div>Search value</div>
                <div style="display:flex">
                    <div><input type="text" v-model="searchNameText" /></div>
                    <div style="margin-left:5px"><button class="button-link" @click="onSearch" >Search</button></div>
                </div>
            </div>                        
        </div>
        <div class="select-button-container div-body" style="overflow-y: auto;">
            <div class="select-button-container" style="flex-direction:column;width:100%">
                <CustomerItem v-for="customer in customers" :key="customer.customerNumber" :itemmodel="customer" :categoryList="categoryList" @on-error="onError" @on-delete="onDelete" @on-dirty="onDirty" @on-serverprocessing="onServerProcessing" />
            </div>
        </div>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import Header from "../../components/Shared/Header";
import Button from "../../components/Shared/Button";
import ButtonModel from "../../models/ButtonModel";
import CustomerItem from "../../components/Administrator/CustomerItem"
import apiCustomer from "../../api/apiCustomer";
import apiCategory from "../../api/apiCategory";

import globalStatus from "../../composables/globalStatus";
import { ref } from '@vue/reactivity';

export default {
    components: { Header, Button, CustomerItem },
    setup() {
        
        const { fetchData, isProcessing, errorMessage, resetStatus, processingMessage, setMessage } = globalStatus();
        
        var customers_base = [];
        const customers = ref([]);
        const searchNameText = ref("");
        const categoryList = ref([]);


        const btnExit = ButtonModel.CreateRoute("Exit", "Home");
        const btnAdd = ButtonModel.CreateClick("New Customer");
        const btnImport = ButtonModel.CreateClick("Import Customers");

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty            
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onDirty = (isDirty) => {
            if (isDirty) dirtyCount++;
            else dirtyCount--;
        };
        

        const onError = (err) => {
            console.error(err);
            errorMessage.value = err;
        };

        const getData = async () => {
            try {
                resetStatus("Loading...");
                categoryList.value = await apiCategory.getList();
                customers_base = await apiCustomer.getAll();
                customers.value = customers_base;                
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data";
            }
            fetchData.value = false;
        };
        getData();

        const onServerProcessing = (process) => {
            isProcessing.value = process
            if (process) setMessage("Saving...");
        };

        return {
            btnExit,
            btnAdd,
            btnImport,
            customers,            
            fetchData,
            errorMessage,
            onError,
            searchNameText,
            categoryList,                      
            onDirty, processingMessage, onServerProcessing, isProcessing
        };
    }
};
</script>

<style></style>
