import PiReloadOptionModel from "./PiReloadOptionModel"
import PiBucketModel from "./PiBucketModel"

export default class SettingModel {
    piReloadOptions:       PiReloadOptionModel[] = [];
    piBuckets:             PiBucketModel[] = [];
    pincodeDigits:         number = 0;
    pincodeExpirationDays: number = 0;
    ticketFont:            number = 0;
    ticketHeader:          string = "";
    defaultCategoryId:     number = 0;
    requireFirstName:      boolean = false;
    requireLastName:       boolean = false;
    requireEmail:          boolean = false;
    requirePhone:          boolean = false;
    requireCity:           boolean = false;
    requireCellPhone:      boolean = false;
    requireState:          boolean = false;
    requireAddress:        boolean = false;
    requireZip:            boolean = false;
    pinCodeColor:          string = "";
    rangeCardColor:        string = "";
    saleItemColor:         string = "";
    systemColor:           string = "";


    get endOfDayExpiration(): boolean {
        return this.pincodeExpirationDays < 0;
    }
    set endOfDayExpiration(value: boolean) {
        if (value) {
            this.pincodeExpirationDays = -1    
        }else {
            this.pincodeExpirationDays = 1
        }
    }

    static from(json: string): SettingModel {        
        return Object.assign(new SettingModel(), json);
    }
}