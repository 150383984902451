export enum HotKeyTypes {
    Other = -1,
    PinTicketSmall = 0,
    PinTicketMedium = 1,
    PinTicketLarge = 2,
    PinTicketKingSize = 3,
    RangeCard = 4,
    RangeCardAuto = 5,
    SaleItem = 6,
    PinTicketCustom = 7,
    PinTicketCategory = 8,
    TurnstileEntry = 9,
    Route = 100,
    Clickable = 101
}


export enum ButtonTypes {    
    Route = 100,
    Clickable = 101,
    Action = 101,
    Other = 200,
}


export enum DiscountTypes {
    Amount = 0,
    Rate = 1,
}

export enum InventoryTypes {
    Inventory = 0,
    Hotkey = 1,
}


export enum CustomerRelationshipTypes {
    Single = 0,
    Parent = 1,
    Child =2
}