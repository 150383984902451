<template>
    <div class="tenant-item card-body" v-if="p_tenantModel.isVisible">        
        <div type="button" class="card-header">
            {{ p_tenantModel.tenantName }}
        </div>
        <div class="content">
            <div class="administrator-button-bar"></div>
            <div class="tenant-input-container">
                <span>Id:</span>
                <input class="tenant-input-item" type="text" v-model="p_tenantModel.tenantId" readonly />
            </div>

            <div class="tenant-input-container">
                <span>Address:</span>
                <textarea class="tenant-input-item" v-model="p_tenantModel.address" readonly></textarea>
            </div>

            <div class="tenant-input-container">
                <span>License Key:</span>
                <div class="tenant-input-item" style="display:flex;justify-content: flex-end;">
                    <img src="../../assets/copy.png" title="copy" style="cursor: pointer; margin-right:5px" @click="copyToClipboard(p_tenantModel.tenantLicense)" />
                    <input style="width:100%;" type="text" v-model="p_tenantModel.tenantLicense" readonly />
                </div>
            </div>

            <div class="hotkey-input-container" style="justify-content: flex-start">
                <input @change="onSave" type="checkbox" :id="'chkIsEnabled' + p_tenantModel.tenantId" v-model="p_tenantModel.isEnabled" />
                <label :for="'chkIsEnabled' + p_tenantModel.tenantId">Active</label>
            </div>

            <div class="hotkey-input-container" style="justify-content: flex-start">
                <input @change="onSave" type="checkbox" :id="'chkEnablePos' + p_tenantModel.tenantId" v-model="p_tenantModel.enablePos" />
                <label :for="'chkEnablePos' + p_tenantModel.tenantId">Enable POS</label>
            </div>

            <div class="hotkey-input-container" style="justify-content: flex-start">
                <input @change="onSave" type="checkbox" :id="'chkEnableScheduler' + p_tenantModel.tenantId" v-model="p_tenantModel.enableScheduler" />
                <label :for="'chkEnableScheduler' + p_tenantModel.tenantId">Enable Scheduler</label>
            </div>

            <div class="tenant-input-container">
                <button class="button-link" @click="addUser">Add User</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import apiTenant from "../../api/apiTenant";

export default {    
    props: ["tenantModel"],
    setup(props, { emit }) {
        const p_tenantModel = ref(props.tenantModel);
        

        const onSave = async () => {
            try {
                await apiTenant.save(p_tenantModel.value);
            } catch (err) {
                console.error(err);
                emit("on-error", "Error saving site license");
            }
        };

        const copyToClipboard = (data) => {
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = data;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        };

        const addUser = () => {            
            emit("on-addUser", p_tenantModel.value);
        };

        

        return { p_tenantModel, onSave, copyToClipboard, addUser };
    }
};
</script>

<style>
.tenant-item {
    width: 500px;
    min-width: 100px;
}

.tenant-input-container {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.tenant-input-item {
    width: 300px;
}
</style>
