<template>
    <div class="template-base">
        <MessageBox :caption="messageBox_caption" :message="messageBox_Message" :tag="messageBox_Tag" v-if="messageBox_show" @on-no="onMessageBoxNo" @on-yes="onMessageBoxYes_Delete" />
        <Header Title="Hotkey Groups" class="div-header" :isProcessing="fetchData || isProcessing" :errorMessage="errorMessage" :processingMessage="processingMessage"/>
        <div class="menu-bar">
            <Button class="x-smaller-button" :buttonModel="btnExit" />            
        </div>
        <div style="overflow-y:scroll">
            <MenuGroupItem v-for="menuGroup in menuGroups" :key="menuGroup.menuGroupId" :menuGroup="menuGroup" @on-delete="onDelete" @on-add="onAdd" @on-swap="onSwap" />
        </div>
    </div>
</template>

<script>
import { onBeforeRouteLeave } from "vue-router";
import apiMenuGroup from "../../api/apiMenuGroup";
import Header from "../../components/Shared/Header";
import Button from "../../components/Shared/Button";
import MessageBox from "../../components/Shared/MessageBox";
import ButtonModel from "../../models/ButtonModel";
import { ref } from "vue";
import globalStatus from "../../composables/globalStatus";
import MenuGroupItem from "../../components/Administrator/MenuGroupItem";
// import MenuGroupModel from "../../models/MenuGroupModel";

export default {
    components: { Header, Button, MessageBox, MenuGroupItem },
    setup() {
        const { fetchData, isProcessing, errorMessage, resetStatus, processingMessage } = globalStatus();
        
        const btnExit = ButtonModel.CreateRoute("Exit", "Home");
        const btnAdd = ButtonModel.CreateClick("New Menu Group");

        const menuGroups = ref([]);

        const messageBox_caption = ref("");
        const messageBox_Message = ref("");
        const messageBox_Tag = ref(null);
        const messageBox_show = ref(false);

        var dirtyCount = 0;

        onBeforeRouteLeave((/*to, from*/) => {
            // check if any components are dirty
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        });

        window.onbeforeunload = () => {
            if (dirtyCount > 0) {
                const answer = window.confirm("Do you really want to leave? You have unsaved changes!");
                if (!answer) return false;
            }
        };

        const onError = (err) => {
            console.error(err);
            errorMessage.value = err;
        };

        const getData = async () => {
            try {
                resetStatus("Loading...");
                menuGroups.value = await apiMenuGroup.getAll();
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error loading data";
            }
            fetchData.value = false;
        };
        getData();

        const onAdd = async (item) => {
            try {
                resetStatus("Creating Menu Group...");
                await apiMenuGroup.create(item.menuGroupId);
                menuGroups.value = await apiMenuGroup.getAll();

                // TODO: Update ui without getting from server
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error creating new menu group";
            }
            fetchData.value = false;
        };

        const onSwap = async (item1, item2) => {
            try {                
                // Verify both items have the same parent
                var parentId1 = menuGroups.value.filter((x) => x.menuGroupId === item1)[0].parentId;
                var parentId2 = menuGroups.value.filter((x) => x.menuGroupId === item2)[0].parentId;                

                if (parentId1 === parentId2) {                    
                    resetStatus("Updating...");
                    await apiMenuGroup.swap(item1, item2);
                    menuGroups.value = await apiMenuGroup.getAll();

                    // TODO: Update ui without getting from server
                }
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error moving menu group";
            }
            fetchData.value = false;
        };

        const onDelete = async (item) => {
            messageBox_caption.value = "Delete Menu Group";
            messageBox_Message.value = "Are you sure you want to delete Menu Group " + item.menuGroupName + " and all of it's children?";
            messageBox_Tag.value = item;
            messageBox_show.value = true;            
        };

        const onMessageBoxYes_Delete = async (item) => {
            try {
                resetStatus("Deleting Menu Group...");
                await apiMenuGroup.delete(item.menuGroupId);
                menuGroups.value = await apiMenuGroup.getAll();
                // TODO: Update ui without getting from server
            } catch (err) {
                console.error(err);
                errorMessage.value = "Error deleting menu group";
            }
            fetchData.value = false;

            onMessageBoxNo();
        };

        const onMessageBoxNo = () => {
            messageBox_show.value = false;
            messageBox_caption.value = "";
            messageBox_Message.value = "";
            messageBox_Tag.value = null;
        };

        return {
            btnExit,
            btnAdd,
            menuGroups,
            fetchData,
            errorMessage,
            onError,
            onAdd,
            onDelete,
            messageBox_caption,
            messageBox_Message,
            messageBox_Tag,
            messageBox_show,
            onMessageBoxNo,
            onMessageBoxYes_Delete,
            onSwap, 
            processingMessage, isProcessing
        };
    }
};
</script>

<style></style>
