import InventoryModel from "./InventoryModel"

export default class CartItemModel {
    inventoryItem : InventoryModel = new InventoryModel();
    quantity: number = 1;

    constructor(inventoryItem: InventoryModel, quantity: number = 1){
        this.inventoryItem = inventoryItem;
        this.quantity = quantity;
    }

}