<template>
    <div class="backdrop" @click.self="onCancel">
        <div class="new-user">
            <div style="display:flex;justify-content:center">New User</div>
            <div style="margin-bottom:10px"><hr /></div>
            <div style="display:flex;justify-content:space-between; flex-direction: column;height:100%">
                <div class="tenant-input-container">
                    <span>Site:</span>
                    <input class="tenant-input-item" type="text" v-model="p_tenantName"  readonly />
                </div>
                <div class="tenant-input-container">
                    <span>Name:</span>
                    <input class="tenant-input-item" type="text" v-model="displayName" />
                </div>
                <div class="tenant-input-container">
                    <span>Email:</span>
                    <input class="tenant-input-item" type="text" v-model="username" />                    
                </div>
                <div class="tenant-input-container">
                    <span>Password:</span>
                    <input class="tenant-input-item" type="password" v-model="password" />                    
                </div>
                <div class="tenant-input-container">
                    <span>Re-type Password:</span>
                    <input class="tenant-input-item" type="password" v-model="password2" />                    
                </div>
                <div class="new-user-button-bar">
                    <button class="new-user-button" @click="onCreate">
                        Create User
                    </button>
                    <button class="new-user-button" @click="onCancel" ref="btnCancel">
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
import NewUserModel from "../../models/NewUserModel"

export default {
    props: ["tenantId", "tenantName"],
    setup(props, { emit }) {        
        const btnCancel = ref(null);
        const displayName = ref('');
        const username = ref('');
        const password = ref('');
        const password2 = ref('');
        const p_tenantName = ref(props.tenantName)

        const onCancel = () => {
            emit("on-cancel");
        };

        const onCreate = () => {
            var newUser = new NewUserModel();            
            newUser.tenantId = props.tenantId;
            newUser.displayName = displayName.value;
            newUser.username = username.value;
            newUser.passkey = password.value            
            emit("on-createUser", newUser);
        };

        onMounted(() => {
            btnCancel.value.focus();
        });

        return { btnCancel, onCancel, onCreate, username, password, password2, p_tenantName, displayName };
    }
};
</script>

<style>
.new-user {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -205px;
    margin-left: -255px;
    width: 500px;
    height: 400px;
    background: var(--main-background);
    border-radius: 10px;
    padding: 30px;
    border: 1px solid var(--card-body-border);
}

.new-user-button-bar {
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    margin: 0px;
}

.new-user-button {
    /* background-color: cornflowerblue; */
    color: var(--button-color);
    padding: 10px;
    margin-right: 10px;
    width: 125px;
}
</style>
