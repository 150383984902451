<template>
    <div class="setting-item">
        <div class="setting-header">
            <div>Hot Key Colors</div>
            <div class="setting-header-bar"><hr /></div>
            <div style="display:flex;flex-direction:column">  
                <div class="setting-input-container">
                <span class="setting-name">Pin Code</span>
                <input @change="onSave" type="color" id="colorPinCode" v-model="p_settings.pinCodeColor"/>
            </div>

            <div class="setting-input-container">
                <span class="setting-name">Range Card</span>
                <input @change="onSave" type="color" id="colorRangeCard" v-model="p_settings.rangeCardColor"/>
            </div>

            <div class="setting-input-container">
                <span class="setting-name">Sale Item</span>
                <input @change="onSave" type="color" id="colorSaleItem" v-model="p_settings.saleItemColor"/>
            </div>

            <div class="setting-input-container">
                <span class="setting-name">System</span>
                <input @change="onSave" type="color" id="colorSystem" v-model="p_settings.systemColor"/>
            </div>              
            </div>
        </div>

    </div>
</template>

<script>
import { ref } from "vue";

export default {
    props: ["settings"],
    components: {},
    setup(props, { emit }) {
        const p_settings = ref(props.settings);

        const onSave =  () => {
            emit("on-save-colors");                    
        };

        return { p_settings , onSave};
    },
};
</script>

<style>
.hot-key-colors-button {
    width: 120px;
}
</style>
