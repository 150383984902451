import { HotKeyTypes } from "../common/enums";
import Utilities from "../common/utilities"

export default class HotkeyModel {
    keyId: string = "";
    keyCaption: string = "";
    hotKeyCmd: string = "";
    hotKeyType: HotKeyTypes = HotKeyTypes.Other;
    displayed: boolean = false;
    categoryId: number = -1;
    price: number = 0;
    toAccount: number = 0;
    displayOrder: number = 0;
    customBalls: number = 0;
    keyTypeDisplay: string = "";
    route: string = "";
    tag: any = null;
    action: any = null;    
    updateCustomerCategory: boolean = false;
    keyColor: string = "";
    isVisible: boolean = true; 
    useCaption: boolean = false;   

    get priceDisplay(): string {
        return this.price.toFixed(2);
    }
    set priceDisplay(value: string) {
        this.price = parseInt(value);
    }

    get toAccountDisplay(): string {
        return this.toAccount.toFixed(2);
    }
    set toAccountDisplay(value: string) {
        this.toAccount = parseInt(value);
    }

    static from(json: string): HotkeyModel {
        let model = Object.assign(new HotkeyModel(), json);
        return model;
    }

    static Create(keyCaption: string, keyColor: string = "", hotKeyType: HotKeyTypes = HotKeyTypes.Other, route: string = "", keyId = ""): HotkeyModel {
        let button = new HotkeyModel();
        button.keyCaption = keyCaption;
        button.hotKeyType = hotKeyType;
        button.route = route;
        button.keyColor = keyColor === "" ? "var(--button-backgroud)" : keyColor;
        button.keyId = keyId;
        return button;
    }
}
