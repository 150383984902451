<template>
  <div class="resource-item card-body" v-if="true" @click="onSelect()">

        <div type="button" class="card-header resource-header">
            {{new Date(p_resource.slotStart).toLocaleString("en-US", { timeStyle: "short" })}}
        </div>
        <div class="resource-item-details">            
            <div class="resource-item-details-section">
                {{p_resource.scheduleResourceName}}
            </div>
            <div class="resource-item-details-section resource-item-price">
                {{new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(p_resource.price)}}
            </div>            
            <div class="resource-item-details-section">
                {{p_resource.duration}} Minutes
            </div>
            <div class="resource-item-details-section">
                {{p_resource.minPlayers}} - {{p_resource.maxPlayers}} Players
            </div>
            <div class="resource-item-details-section">
                {{p_resource.balls}} Complimentary Balls
            </div>
            
                                
            
        </div>
    </div>
</template>

<script>
import { ref } from "vue";
export default {
props: ["resource"],
    setup(props, {emit}) {
        const p_resource = ref(props.resource);
                
        const onSelect = () => {            
            emit("on-selected", p_resource.value);
        }

        return {            
            p_resource,                        
            onSelect
        };
    }
}
</script>


<style>

.resource-header{
    font-size: 16pt;    
}

.resource-item {
    width: 200px;
    min-width: 100px;
    cursor: pointer;
}

.resource-item-details {
    display: flex;
    flex-direction: column;    
    text-align: center;
}

.resource-item-details-section {
    padding: 5px;
}

.resource-item-price {
    font-size: 14pt;
    color: forestgreen;
}

</style>